<template>
  <div>
    <!-- 公共导航 -->
    <idphoto-mheader></idphoto-mheader>

    <div class="content">
      <div class="banner">
        <img src="../../../static/imgs/idphoto/m_banner@2xabout.png"
             alt="">
      </div>

      <div class="service c">
        <img src="../../../static/imgs/idphoto/m_guanyuwome@2x.png"
             alt="">
      </div>

      <div class="addr c">
        <img src="../../../static/imgs/idphoto/lianxiwomen_ditu@2x.png"
             alt="">
        <div class="content-us">
          <h4>联系我们</h4>
          <p>电话：0512-87816685</p>
          <p>邮箱：feedback@techpuwu.com </p>
          <p>微信公众号：techpuwu</p>
          <h4>公司地址：</h4>
          <p class="small">中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼415室</p>
        </div>
      </div>
    </div>

    <!-- 公共底部 -->
    <idphoto-mfooter></idphoto-mfooter>
  </div>
</template>
<script>
import IdphotoMheader from '../../../component/idphoto/m_header/m_header.vue';
import IdphotoMfooter from '../../../component/idphoto/m_footer/m_footer.vue';
export default {
  name: 'm_about',
  components: {
    IdphotoMheader,
    IdphotoMfooter
  },
  data () {
    return {
    }
  },
  mounted () {
    document.title = "证件照";
  },
}
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/m_app.less";
@import "./m_about.less";
</style>
<template>
  <div>

    <!-- 公共导航 -->
    <div v-if="isMobile">
      <idphoto-mheader></idphoto-mheader>
    </div>
    <div v-else>
      <idphoto-header></idphoto-header>
    </div>

    <div class="content">
      <div>
        <p class="title"
           style=" line-height:33pt; margin:0pt"><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">隔离在家，为了情人节领证，</span><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">异地恋拍结婚登记</span><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">照的我们</span><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">选择这么拍</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">隔离在家，希望疫情早点过去，年前计划情人节那天去</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">跟对象</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">领证，没想到隔离在家不能出门，领证的日子从202020202的对称日延期到2月14的正常情人节，没想到居然一拖就拖到了今年的白色情人节。</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">结果今年的白色情人节居然是周六，真的是想领证的心一起一伏，为难我们，于是</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">跟对象</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">商量了下，不管怎么样先把照片拍了再说，只有</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">有</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">机会就立马去登记。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">那么问题来了，异地的我们要怎么去拍一张合格又好看的结婚登记照呢，小编本着试一试的态度打开度娘，找了下在家怎么拍一张结婚登记照，发现居然有一款可以拍结婚登记照的软件。</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="280bdf44-5ceb-47f6-82a1-703dc593498d.001.jpeg"
               width="554"
               height="192"
               alt="隔离在家，为了情人节领证，异地恋拍结婚登记照的我们有多卑微"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">在家怎么拍结婚登记照</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">上面经验</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">中说是</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">只要上传两个人正面清晰的照片就可以做，于是我去翻了下相册，企图找一张稍微正经的可以做结婚证件照的照片来，结果发现居然一张都没有，甚至没有两个人正经的正面单张照片，</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">经过</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">九九八十一</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">难，我终于穿过重重困难，</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">云拍摄</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">了一张好看的结婚登记照。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="280bdf44-5ceb-47f6-82a1-703dc593498d.002.jpeg"
               width="554"
               height="554"
               alt="隔离在家，为了情人节领证，异地恋拍结婚登记照的我们有多卑微"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">结婚登记照</span><span style="color:#999999; font-family:微软雅黑; font-size:9pt">拍成功</span><span style="color:#999999; font-family:微软雅黑; font-size:9pt">了开心</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">那么</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">怎么云拍一张</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">合格的结婚证件照呢。以一个“过来人的身份”告诉大家拍摄的流程。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">1 首先，得有一枚对象</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">2 其次，选择一面光线均匀纯色的墙，可以选择站在白墙前。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">3 用后置摄像头进行拍摄，可以多拍摄几张，然后从拍摄的照片中选择最好看的照片</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">4 上传到软件上 让设计师帮忙修图</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">给大家看下我们拍的照片</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="280bdf44-5ceb-47f6-82a1-703dc593498d.003.jpeg"
               width="554"
               height="636"
               alt="隔离在家，为了情人节领证，异地恋拍结婚登记照的我们有多卑微"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">对象在家拍的照片</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="280bdf44-5ceb-47f6-82a1-703dc593498d.004.jpeg"
               width="516"
               height="650"
               alt="隔离在家，为了情人节领证，异地恋拍结婚登记照的我们有多卑微"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">我自己在家拍的照片</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">把照片上传至【结婚证件照】后生成的照片长这样</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="280bdf44-5ceb-47f6-82a1-703dc593498d.005.jpeg"
               width="554"
               height="377"
               alt="隔离在家，为了情人节领证，异地恋拍结婚登记照的我们有多卑微"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">这个是设计师精修出来的图，很满意</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="280bdf44-5ceb-47f6-82a1-703dc593498d.006.jpeg"
               width="554"
               height="415"
               alt="隔离在家，为了情人节领证，异地恋拍结婚登记照的我们有多卑微"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">这个是打印寄过来的照片，实物比图片好看一百倍</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">但是整体来说这个效果我是真的很满意了，足不出户就拍了一张很满意的结婚登记照，开开心心，打印出来寄过来的照片也很好，真的很满意了，需要的可以
            试试呀，</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">需要拍结婚登记照的都可以试试~</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
      </div>
    </div>

    <!-- 公共底部 -->
    <div v-if="isMobile">
      <idphoto-mfooter></idphoto-mfooter>
    </div>
    <div v-else>
      <idphoto-footer></idphoto-footer>
    </div>
  </div>
</template>
<script>
import IdphotoHeader from '../../../../component/idphoto/header/header.vue';
import IdphotoFooter from '../../../../component/idphoto/footer/footer.vue';
import IdphotoMheader from '../../../../component/idphoto/m_header/m_header.vue';
import IdphotoMfooter from '../../../../component/idphoto/m_footer/m_footer.vue';
export default {
  name: 'about',
  components: {
    IdphotoHeader,
    IdphotoFooter,
    IdphotoMheader,
    IdphotoMfooter
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
}
</script>
<style lang="less" scoped>
@import "../../../../styles/idphoto/app.less";
@import "../artical.less";
</style>
<template>
  <div class="footer">
    <div class="footer-content c">
      <div class="flex_box map">
        <div class="footer-flex" v-for="(item, index) in navNames" :key="index">
          <div class="flex_1" :class="{ white: index == 0 }">|</div>
          <div class="flex_1" @click="jumpUrl(index)">
            <a>{{ item }}</a>
          </div>
        </div>
      </div>

      <div class="copyright">
        Copyright@2018苏州普物科技有限公司
        <a href="http://beian.miit.gov.cn" target="_blank"
          >苏ICP备18052115号-1</a
        >
      </div>
      <div class="addr">
        地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼415室
      </div>
      <div class="contact">
        电话：0512-87816685 &nbsp;&nbsp;&nbsp;&nbsp; 邮箱：feedback@techpuwu.com
      </div>
    </div>
  </div>
</template>
<script>
import commonDialog from "../../../component/dialog/dialog.vue";
export default {
  name: "idphoto-footer",
  components: {
    commonDialog,
  },
  props: {},
  data() {
    return {
      curIndex: 0,
      curName: "",
      navNames: ["首页", "结婚登记照", "印象证件照", "新闻资讯", "关于我们"],
    };
  },
  mounted() {
    this.curName = this.$route && this.$route.name;
    switch (this.curName) {
      case "index":
        this.curIndex = 0;
        break;

      case "jiehun":
        this.curIndex = 1;
        break;

      case "yinxiang":
        this.curIndex = 2;
        break;

      case "zixun":
        this.curIndex = 3;
        break;

      case "idp_about":
        this.curIndex = 4;
        break;
    }
  },
  methods: {
    jumpUrl(idx) {
      //   let index = idx - 1;
      let names = ["index", "jiehun", "yinxiang", "zixun", "idp_about"];
      this.$router
        .push({
          name: names[idx],
        })
        .catch((err) => {
          err;
        });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/app.less";
@import "./footer.less";
</style>
<template>
  <div class="photo-block-wrap">
    <div class="photo-block">
      <div class="photo-block-right">
        <div class="photo-title">手机误删照片恢复</div>
        <div class="photo-subtitle">安全·专业·可信赖</div>
        <div class="photo-intro">
          10年恢复数据经验<br class="pc-show" /><span class="phone-show">&nbsp;&nbsp;</span>24小时客服在线答疑<br />
          独立恢复数据，安全可信赖
        </div>
        <div class="photo-erweima pc-show">
          <div class="erweima">
            <img src="../../../static/imgs/rephoto/pc/design.jpg"
                 alt="">
          </div>
          <p class="tel pc-show">修复师微信<br />客服电话：{{data.tel}}</p>
        </div>
      </div>
      <div class="photo-block-left">
        <div class="tel-edit pc-show">
          <img src="../../../static/imgs/rephoto/pc/home-tel.png"
               alt="">
        </div>
        <div class="tel-edit phone-show">
          <img src="../../../static/imgs/rephoto/phone/home_huifu.png"
               alt="">
        </div>
      </div>
      <!-- <br class="clear" /> -->
      <div class="edit-btn phone-show mt6"
           @click="download">
        立即恢复
      </div>
      <div class="home-slide phone-show gray">
      </div>
    </div>
  </div>
</template>

<script>
import data from '../../../static/json/data.json'
export default {
  name: 'home-redata',
  data () {
    return {
      data: data,
      isIOS: false,
      iosUrl: "https://apps.apple.com/cn/app/id1497453325",
      anzhuoApk: "https://oss.puwu.info/rephoto/update/照片修复.apk",
    }
  },
  mounted () {
    let ua = navigator.userAgent.toLowerCase();
    this.isIOS = ua.indexOf('mac os') > -1;
  },
  methods: {
    download () {
      let url = this.isIOS ? this.iosUrl : this.anzhuoApk;
      location.href = url;
    }
  }

}
</script>
<template>
  <div class="top-pic-wrap">
    <div class="pc-show">
      <img src="../../../static/imgs/rephoto/pc/home-pic2.png" alt="" />
    </div>
    <div class="phone-show" style="position: relative">
      <img src="../../../static/imgs/rephoto/phone/home_pic.png" alt="" />
      <!-- <video controls="controls"
             muted
             loop
             autoplay="autoplay"
             :src="videoUrl">
      </video> -->

      <div class="home-slide phone-show"></div>
    </div>

    <div class="top-title">
      <h3>修复旧照片<br />梦回好时光</h3>

      <div class="home-download pc-show">
        <span @click="iosLoad">iOS下载</span>
        <span @click="anzhuoLoad">安卓下载</span>
      </div>

      <div class="home-download phone-show" @click="download">
        <span>立即下载</span>
      </div>

      <div class="top-txt">
        <span>AI人像翻新</span>
        <span>人工修复</span>
        <span>创意修复</span>
        <span>照片恢复</span>
      </div>

      <div class="banner-privacy">
        <p>开发者：苏州普物科技有限公司</p>
        <p>
          照片修复 V2.1.8
          <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;<a
            class="blue"
            href="https://oss.puwu.info/rephoto/web/privacy_oldrephoto.html"
            target="_blank"
            >隐私协议</a
          >
        </p>
      </div>
    </div>

    <div class="top-qrcode pc-show">
      <img src="../../../static/imgs/rephoto/pc/home-qrcode.png" alt="" />
      <!-- <p>立即扫码下载</p> -->
    </div>


    <common-dialog
      :showDialog="isShowDialog"
      :dialogTitle="dialogTitle"
      :dialogContent="dialogContent"
      :showSingleBtn="showSingleBtn"
      :isAlignLeft="isAlignLeft"
      @rightBtnFn="showDialog"
    >
  </div>
</template>

<script>
import commonDialog from "../../../component/dialog/dialog.vue";
export default {
  name: "home-top",
  components: {
    commonDialog,
  },
  data() {
    return {
      // 是否ios
      isIOS: false,
      iosUrl: "https://apps.apple.com/cn/app/id1497453325",
      anzhuoUrl: "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.rephoto",
      anzhuoApk: "https://oss.puwu.info/rephoto/update/照片修复.apk",
      videoUrl: require("../../../static/imgs/rephoto/young1.mp4"),

      isShowDialog: false,
      dialogTitle: "获取权限说明",
      showSingleBtn: true,
      isAlignLeft: true,
      dialogContent: "",
    };
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    this.isIOS = ua.indexOf("mac os") > -1;
  },
  methods: {
    iosLoad() {
      //   location.href = this.iosUrl;
      window.open(this.iosUrl, "_blank");
    },
    anzhuoLoad() {
      //   location.href = this.anzhuoUrl;
      window.open(this.anzhuoUrl, "_blank");
    },
    download() {
      let goUrl = this.isIOS ? this.iosUrl : this.anzhuoApk;
      window.open(goUrl, "_blank");
    },
    showAuthorityBox() {
      this.dialogTitle = "获取权限说明";
      this.isShowDialog = true;
      this.showDoubleBtn = false;
      this.showSingleBtn = true;
      this.isAlignLeft = true;
      this.dialogContent =
        "为给您提供更优质的服务，照片修复App 需要向您申请以下权限：<br/>1. 相册读写权限： 用于您上传照片进行修复/上色；<br/>2. 相机权限：用于拍摄照片进行修复/上色；<br/>3. 存储权限： 用于保存修复/上色成功的照片；<br/>4. 推送通知权限：用于接收客服回复的消息；<br/>5. 语音输入权限：用于语音咨询客服问题。";
    },
    showDialog() {
      this.isShowDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./home-top.less";
</style>
<template>
  <div>

    <!-- 公共导航 -->
    <div v-if="isMobile">
      <idphoto-mheader></idphoto-mheader>
    </div>
    <div v-else>
      <idphoto-header></idphoto-header>
    </div>

    <div class="content">
      <div>
        <p style="margin:0pt"><span style="font-family:微软雅黑; font-size:24pt; font-weight:bold">哈士奇结婚证件照你见过吗？</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
        <p style="margin:0pt; text-align:justify"><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">都说哈士奇是上帝</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">画狼时</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">打的草稿，有美貌但二，小编只想</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">默默说</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">一句，上帝是公平的。</span></p>
        <p style="margin:0pt; text-align:justify"><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">比如没事就给主人整个二十万的装修计划，比如，在家横天横地，在外胆小如鼠，甚至连一直泰</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">迪</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">的大叫都怕，别问我怎么知道，因为我养了。你看到的可能是这样</span></p>
        <p style="margin:0pt; text-align:justify"><img src="64843d75-cc74-443c-9778-bfe74184aa1c.001.png"
               width="554"
               height="513"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="margin:0pt; text-align:justify"><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">这样。</span></p>
        <p style="margin:0pt; text-align:justify"><img src="64843d75-cc74-443c-9778-bfe74184aa1c.002.png"
               width="554"
               height="381"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="margin:0pt; text-align:justify"><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">尽管小编在哈士奇大帝的百般蹂躏下生不如死，但是不得不说，这绝世美貌也不是</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">是</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">个狗子都有的。</span></p>
        <p style="margin:0pt; text-align:justify"><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">有一天这货心情极好对着镜头安安静静，小编赶紧</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">咔嚓拍</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">了两张照片。去</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">楼下仍</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">垃圾顺便看见我儿子的女朋友张二狗在遛弯，马上举起相机咔咔拍了两张带回去给我儿子看，全</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">当今日份社交</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">。</span></p>
        <p style="margin:0pt; text-align:justify"><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">上来看照片的时候我突然有个大胆的想法，要是能给我儿子跟它女朋友整张结婚照，也象征性他们结婚了。我真是个感人肺腑的老母亲。</span>
        </p>
        <p style="margin:0pt; text-align:justify"><img src="64843d75-cc74-443c-9778-bfe74184aa1c.003.png"
               width="554"
               height="577"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="margin:0pt; text-align:justify"><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">于是我打开应用市场</span><span style="font-family:Arial; font-size:12pt; letter-spacing:0.15pt">-</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">搜索了【结婚证件照】上传了一张我儿子的照片跟她女朋友张二狗的照片。过了半个小时。照片就好了，当当当当。</span>
        </p>
        <p style="margin:0pt; text-align:justify"><img src="64843d75-cc74-443c-9778-bfe74184aa1c.004.png"
               width="554"
               height="384"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="margin:0pt; text-align:justify"><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">顺便小</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">编又帮隔壁小区张翠华跟王大壮做了张，（锁住，请不要来插足我儿子的感情生活）</span>
        </p>
        <p style="margin:0pt; text-align:justify"><img src="64843d75-cc74-443c-9778-bfe74184aa1c.005.png"
               width="32"
               height="32"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:character; -aw-rel-vpos:line; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="margin:0pt; text-align:justify"><img src="64843d75-cc74-443c-9778-bfe74184aa1c.006.png"
               width="554"
               height="409"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" /><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">以及</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt"> </span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">软软跟萌</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">萌</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">的结婚证件照，毕竟软软萌</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">萌</span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">从小青梅竹马，老阿姨甘愿为他们把民政局搬来。</span></p>
        <p style="margin:0pt; text-align:justify"><span style="font-family:Arial; font-size:12pt; letter-spacing:0.55pt">&#xa0;</span></p>
        <p style="margin:0pt; text-align:justify"><img src="64843d75-cc74-443c-9778-bfe74184aa1c.007.png"
               width="554"
               height="366"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="margin:0pt; text-align:justify"><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">有需要的都去下载【结婚证件照】</span><span style="font-family:Arial; font-size:12pt; letter-spacing:0.15pt">app </span><span style="font-family:微软雅黑; font-size:12pt; letter-spacing:0.15pt">呀，不管是小哥哥小姐姐的，还是家里动物的都能做！都给我去下载它</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><img src="64843d75-cc74-443c-9778-bfe74184aa1c.008.png"
               width="554"
               height="234"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" /><span style="font-family:等线; font-size:10.5pt">&#xad;</span><span style="font-family:等线; font-size:10.5pt">&#xad;</span><span style="font-family:等线; font-size:10.5pt">&#xad;</span></p>
      </div>
    </div>

    <!-- 公共底部 -->
    <div v-if="isMobile">
      <idphoto-mfooter></idphoto-mfooter>
    </div>
    <div v-else>
      <idphoto-footer></idphoto-footer>
    </div>
  </div>
</template>
<script>
import IdphotoHeader from '../../../../component/idphoto/header/header.vue';
import IdphotoFooter from '../../../../component/idphoto/footer/footer.vue';
import IdphotoMheader from '../../../../component/idphoto/m_header/m_header.vue';
import IdphotoMfooter from '../../../../component/idphoto/m_footer/m_footer.vue';
export default {
  name: 'about',
  components: {
    IdphotoHeader,
    IdphotoFooter,
    IdphotoMheader,
    IdphotoMfooter
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
}
</script>
<style lang="less" scoped>
@import "../../../../styles/idphoto/app.less";
@import "../artical.less";
</style>
<template>
  <div>
    <!-- 公共导航 -->
    <idphoto-header></idphoto-header>

    <div class="banner c">
      <img src="../../../static/imgs/idphoto/aboutbg@2x.png"
           alt="" />
    </div>

    <div class="content">
      <div class="dest-box">
        <div class="dest-item c">
          <img src="../../../static/imgs/idphoto/ic_zhuanye@2x.png"
               alt="" />
          <h1>专业</h1>
          <p>10年经验专业设计团队，保证每张证件照好看合格</p>
        </div>

        <div class="dest-item c">
          <img src="../../../static/imgs/idphoto/ic_jinyan@2x.png"
               alt="" />
          <h1>经验</h1>
          <p>截至目前，我们已为2000 0+人制作专业证件照</p>
        </div>

        <div class="dest-item c">
          <img src="../../../static/imgs/idphoto/ic_quanmian@2x.png"
               alt="" />
          <h1>全面</h1>
          <p>囊括结婚证件照，常规证 件照，手绘q版，精修正装 照等</p>
        </div>

        <div class="dest-item c">
          <img src="../../../static/imgs/idphoto/ic_ziyuan@2x.png"
               alt="" />
          <h1>资源</h1>
          <p>百名设计师在线修图，让 普通证件照照片华丽变身</p>
        </div>

        <div class="dest-item c">
          <img src="../../../static/imgs/idphoto/ic_gaoxiao@2x.png"
               alt="" />
          <h1>高效</h1>
          <p>百名设计师在线，为你提 供优质创意设计服务</p>
        </div>

        <div class="dest-item c">
          <img src="../../../static/imgs/idphoto/ic_fuwu@2x.png"
               alt="" />
          <h1>服务</h1>
          <p>最专业的客服为您解答， 让你的证件照无后顾之忧</p>
        </div>
      </div>

      <div class="ditu flex_box">
        <div class="map">
          <img src="../../../static/imgs/idphoto/lianxiwomen_ditu@2x.png"
               alt="">
        </div>
        <!-- <div class="map"
             id="dituContent"></div> -->
        <div class="content-us">
          <h1>联系我们</h1>
          <p>电话：0512-87816685</p>
          <p>邮箱：feedback@techpuwu.com </p>
          <p>微信公众号：techpuwu</p>
          <p>公司地址：</p>
          <p class="small">中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼415室</p>
        </div>
      </div>
    </div>

    <!-- 公共底部 -->
    <idphoto-footer></idphoto-footer>
  </div>
</template>
<script>
import IdphotoHeader from '../../../component/idphoto/header/header.vue';
import IdphotoFooter from '../../../component/idphoto/footer/footer.vue';
export default {
  name: 'about',
  components: {
    IdphotoHeader,
    IdphotoFooter
  },
  data () {
    return {
    }
  },
  mounted () {
    // document.title = "证件照";
  },
}
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/app.less";
@import "./about.less";
</style>
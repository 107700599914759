<template>
  <div class="pc-magic-wrap">
    <div class="magic_title_wrap">
      <div class="pc_magic_title">
        <span class="pc_magic_logo">
          <img src="../../../static/imgs/magic/logo.png" alt="" /> 水印魔法师
        </span>
        <div class="pc_magic_nav">
          <a class="on" href="javascript:;">首页</a>
          <a href="#advant">特色功能</a>
          <a href="#pro">产品优势</a>
          <!-- <span>更多产品</span> -->
        </div>
      </div>
    </div>

    <div class="magic_intro_wrap">
      <div class="pc_magic_intro">
        <div class="magic_intro_txt">
          <div class="title">水印魔法师·app</div>
          <div class="tip">去照片/视频水印神奇魔法师</div>
          <div class="info">无水印视频一键提取</div>
          <div class="info">手动去除有水印视频</div>
          <div class="info">添加/去除图片水印</div>
          <div class="btns">
            <span class="anzhuo" @click="anzhuoDown">安卓下载</span>
            <span class="ios gray">iOS下载</span>
          </div>
        </div>
        <div class="magic_intro_pic">
          <img src="../../../static/imgs/magic/magic-pic.png" alt="" />
        </div>
      </div>
    </div>

    <div class="magic_logo_title" id="advant">特色功能</div>

    <div class="magic_pro_list">
      <div class="magic_pro_txt">
        <div class="title_1">人工智能图像算法</div>
        <div class="title_2">AI算法去除图片/视频水印</div>
        <div class="txt">
          对于水印区域原始图内容较为复杂的图片（如
          鲜花植物上、建筑上的水印）根据水印附近内 容，自动智能补全图片内容。<br />
          任何不想要的物体利用水印消除笔完整去除水
          印内容，可完美还原照片/视频最初状态。
        </div>
        <div class="btn" @click="anzhuoDown">立即下载</div>
      </div>
      <div class="magic_pro_pic">
        <img src="../../../static/imgs/magic/magic-pic2.png" alt="" />
        <div class="magic_pro_pos">
          <img src="../../../static/imgs/magic/magic-pic22.png" alt="" />
          <p>AI算法去水印</p>
        </div>
      </div>
    </div>

    <div class="magic_pro_list list2">
      <div class="magic_pro_pic">
        <img src="../../../static/imgs/magic/magic-pic3.png" alt="" />
        <div class="magic_pro_pos">
          <img src="../../../static/imgs/magic/magic-pic33.png" alt="" />
          <p>本地算法去水印</p>
        </div>
      </div>
      <div class="magic_pro_txt">
        <div class="title_1">极速处理照片/视频水印</div>
        <div class="title_2">本地算法去除图片/视频水印</div>
        <div class="txt">
          对于水印区域原始图内容较为简单的图片<br />（如天空，大海等上面的水印）<br />
          任何不想要的的物体，<br />
          你都可以自由选取需要清除的范围，一键清除。
        </div>
        <div class="btn" @click="anzhuoDown">立即下载</div>
      </div>
    </div>

    <div class="magic_logo_title" id="pro">产品优势</div>

    <div class="magic_advant">
      <div class="magic_advant_list">
        <img src="../../../static/imgs/magic/magic-pic4.png" alt="" />
        <div class="name">文件保密</div>
        <div class="txt">
          图片处理完后，服务器便会自动清除上传
          的文件，100%保障安全，无需担心隐私泄漏。
        </div>
      </div>
      <div class="magic_advant_list">
        <img src="../../../static/imgs/magic/magic-pic5.png" alt="" />
        <div class="name">文件保密</div>
        <div class="txt">
          成熟的AI算法技术，为图片处理提供更专
          业的服务，支持图片或视频水印增加/去除 等多种服务。
        </div>
      </div>
      <div class="magic_advant_list">
        <img src="../../../static/imgs/magic/magic-pic6.png" alt="" />
        <div class="name">文件保密</div>
        <div class="txt">
          去除水印后的图片或视频在压缩文件大小
          同时，还尽可能地保持了原始文件的画质 不受损坏。
        </div>
      </div>
    </div>

    <div class="magic_download" @click="anzhuoDown">立即下载</div>

    <div class="pc-magic-footer">
      <div class="magic-footer">
        <div class="magic-qrcode">
          <img src="../../../static/imgs/magic/qrcode.png" alt="" />
          <p>扫码下载去水印魔法师app</p>
        </div>
        <div class="magic-footer-txt">
          <h3>水印魔法师—你口袋里魔法去水印神器</h3>
          <p>苏州普物科技有限公司</p>
          <p>地址：苏州工业园区脉山龙大厦1号楼415</p>
          <p>邮箱：feedback@techpuwu&nbsp;&nbsp;电话：0512—87816685</p>
          <p>
            copyright@2018苏州普物科技有限公司
            <a href="http://beian.miit.gov.cn">苏ICP备18052115号—5</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "magic_logo",
  data() {
    return {};
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    if (/mobile|android|iphone|ipad|phone/i.test(ua)) {
      this.$router
        .push({
          name: "m_magic_logo",
        })
        .catch((err) => {
          err;
        });
    }
  },
  methods: {
    anzhuoDown() {
      //   location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.magiclogo";
      window.open(
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.magiclogo"
      );
    },
  },
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./pc_magic.less";
</style>
<template>
  <div class="m-magic-wrap">

    <div class="m_magic_title">
      <img src="../../../static/imgs/magic/logo.png"
           alt="">
      <span class="m_magic_logo">
        水印魔法师
      </span>
    </div>

    <div class="m_magic_intro">
      <div class="title">水印魔法师·app</div>
      <div class="tip">去照片/视频水印神奇魔法师</div>
      <div class="info">支持矩形框去除大片水印</div>
      <div class="info">支持消除笔精细化去除水印</div>
      <div class="btns-wrap">
        <div class="qrcode">
          <img src="../../../static/imgs/magic/m_qrcode.png"
               alt="">
        </div>
        <div class="btns">
          <span class="anzhuo"
                @click="anzhuoDown">安卓下载</span>
          <span class="ios gray">iOS下载</span>
        </div>
      </div>
    </div>

    <div class="magic_logo_title">特色产品</div>

    <div class="magic_advatant">
      <img src="../../../static/imgs/magic/m_ad1.png"
           alt="">
      <img src="../../../static/imgs/magic/m_ad2.png"
           alt="">
      <img src="../../../static/imgs/magic/m_ad3.png"
           alt="">
      <img src="../../../static/imgs/magic/m_ad4.png"
           alt="">
    </div>

    <div class="magic_logo_title">产品优势</div>

    <div class="magic_pro_list">
      <div class="magic_pro_pic">
        <img src="../../../static/imgs/magic/magic-pic2.png"
             alt="">
        <div class="magic_pro_pos">
          <img src="../../../static/imgs/magic/magic-pic22.png"
               alt="">
          <p>AI算法去水印</p>
        </div>
      </div>
      <div class="magic_pro_txt">
        <div class="title_1">人工智能图像算法</div>
        <div class="txt">对于水印区域原始图内容较为复杂的图片（如
          鲜花植物上、建筑上的水印）根据水印附近内
          容，自动智能补全图片内容。<br />
          任何不想要的物体利用水印消除笔完整去除水
          印内容，可完美还原照片/视频最初状态。
        </div>
        <div class="btn"
             @click="anzhuoDown">立即下载</div>
      </div>
    </div>

    <div class="magic_pro_list">
      <div class="magic_pro_pic">
        <img src="../../../static/imgs/magic/magic-pic3.png"
             alt="">
        <div class="magic_pro_pos">
          <img src="../../../static/imgs/magic/magic-pic33.png"
               alt="">
          <p>AI算法去水印</p>
        </div>
      </div>
      <div class="magic_pro_txt">
        <div class="title_1">本地算法去除图片/视频水印</div>
        <div class="txt">
          对于水印区域原始图内容较为简单的图片<br />
          （如天空，大海等上面的水印）<br />
          任何不想要的的物体，<br />
          你都可以自由选取需要清除的范围，一键清除。
        </div>
        <div class="btn"
             @click="anzhuoDown">立即下载</div>
      </div>
    </div>

    <div class="magic-footer-txt">
      <h3>水印魔法师—你口袋里魔法去水印神器</h3>
      <p>苏州普物科技有限公司</p>
      <p>地址：苏州工业园区脉山龙大厦1号楼415</p>
      <p>邮箱：feedback@techpuwu&nbsp;&nbsp;电话：0512—87816685</p>
      <p>copyright@2018苏州普物科技有限公司</p>
      <p><a href="http://beian.miit.gov.cn">苏ICP备18052115号—5</a></p>
    </div>

  </div>
</template>
<script>
export default {
  name: 'm_magic_logo',
  data () {
    return {

    }
  },
  mounted () {
    let ua = navigator.userAgent.toLowerCase();
    let flag = /mobile|android|iphone|ipad|phone/i.test(ua);
    if (!flag) {
      this.$router.push({
        name: 'magic_logo'
      }).catch(err => { err })
    }
  },
  methods: {
    anzhuoDown () {
      location.href = "https://oss.puwu.info/magiclogo/update/水印魔法师.apk";
      //   location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.magiclogo";
    }
  },
}
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./m_magic.less";
</style>
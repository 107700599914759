<template>
  <div>

    <!-- 公共导航 -->
    <div v-if="isMobile">
      <idphoto-mheader></idphoto-mheader>
    </div>
    <div v-else>
      <idphoto-header></idphoto-header>
    </div>

    <div class="content">
      <div>
        <p class="title"
           style=" margin:18pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:18pt; font-weight:bold">奔走相告，结婚证件照app上线啦</span></p>
        <p style=" margin:0pt 0pt 16.8pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">奔走相告，我们策划已久的结婚证件照app终于上线啦。数十位专业人物精修设计师在线修图，确保每张照片都精美好看。只要你上传一张普通的合照，我们就能帮您制作一张好看的结婚证件照。为那些在照相馆拍照表情不自然、介意价格昂贵、天气原因不想出门</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">等等困境</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">一扫而光，花白菜</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">的价做最好</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">看的结婚登记照。</span></p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">目前已上架至各大应用市场，直接搜索 【结婚证件照】即可免费下载</span></p>
        <p style="margin:0pt"><img src="97364998-6d58-4232-9421-5c02c6ea7142.001.jpeg"
               width="554"
               height="161"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">**特色功能**</span></p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">【精修结婚登记照，完美便捷】</span></p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">百名专业设计师在线精修结婚登记照，让你也能拥有像明星一样好看的登记照；品牌相纸冲印照片快速邮寄到您手中。您只要做的就是打开软件，选一张好看规范的合照上传至软件就可以，作图时间为30分钟到2个小时不等，会精细化给您修图，做到每个细节都完美</span>
        </p>
        <p style="margin:0pt"><img src="97364998-6d58-4232-9421-5c02c6ea7142.002.jpeg"
               width="554"
               height="286"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">【500+种证件照尺寸，即拍即用】【AI智能美肤，合格又好看】</span></p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">急需证件照？在纯色背景墙前拍一张照片，仅需一分钟即可获得一张证件照，囊括一寸、二寸照等常用寸照；</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">四六</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">级、计算机、教师资格证等考试报名照；签证、升学照、社保证、身份证等等500种规格。满足证件</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">照所有</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">拍摄场景。只要</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">一</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">分种就能拍好图啦</span></p>
        <p style="margin:0pt"><img src="97364998-6d58-4232-9421-5c02c6ea7142.003.jpeg"
               width="554"
               height="222"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">【精品手绘形象照，创意独特】</span></p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">小清新、动漫、插画、轻</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">奢</span><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">、黑白酷、二次元等画风供您选择。无论是情侣合照或是个人照片，都能帮您画出创意手绘图。无论是用于头像、创意生日礼物都非常受欢迎。</span>
        </p>
        <p style="margin:0pt"><img src="97364998-6d58-4232-9421-5c02c6ea7142.004.jpeg"
               width="554"
               height="277"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">【精美职业正装照，合格专业】</span></p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">一张专业好看的证件照能帮你简历通过率提高35%，让你看起来成熟稳重，优质设计师让你普通照片华丽变身，只需上传一张普通的照片，设计师就会帮您制作一张好看的正装证件照</span>
        </p>
        <p style="margin:0pt"><img src="97364998-6d58-4232-9421-5c02c6ea7142.005.jpeg"
               width="554"
               height="277"
               alt=""
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">无论你是需要拍摄结婚证件照、常规一寸二寸照证件照、还是需要求职要一张好看成熟的正装证件照，还是需要做一张专属头像，都可以来我们平台。期待来这里的每一个人都能获得一张好看的照片。</span>
        </p>
        <p style=" margin:16.8pt 0pt"><span style="color:#1a1a1a; font-family:微软雅黑; font-size:13.5pt">下载传送门--</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
      </div>
    </div>

    <!-- 公共底部 -->
    <div v-if="isMobile">
      <idphoto-mfooter></idphoto-mfooter>
    </div>
    <div v-else>
      <idphoto-footer></idphoto-footer>
    </div>
  </div>
</template>
<script>
import IdphotoHeader from '../../../../component/idphoto/header/header.vue';
import IdphotoFooter from '../../../../component/idphoto/footer/footer.vue';
import IdphotoMheader from '../../../../component/idphoto/m_header/m_header.vue';
import IdphotoMfooter from '../../../../component/idphoto/m_footer/m_footer.vue';
export default {
  name: 'about',
  components: {
    IdphotoHeader,
    IdphotoFooter,
    IdphotoMheader,
    IdphotoMfooter
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
}
</script>
<style lang="less" scoped>
@import "../../../../styles/idphoto/app.less";
@import "../artical.less";
</style>
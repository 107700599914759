<template>
  <div>
    <div class="nav flex_box flex_align_center">
      <div class="logo flex_1">
        <img
          src="../../../static/imgs/idphoto/m_logo_puwu@2x.png"
          alt=""
        />证件照
      </div>
      <div class="menu" @click="showMenu"></div>
    </div>
    <!-- 导航 -->
    <div class="navbar-box" v-show="showBox" @click="showMenu">
      <div class="navbar" :class="{ active: showBox }">
        <div class="menu"></div>

        <div class="menu-list">
          <!-- <a class="link on">首页</a>
          <a class="link">结婚证件照</a>
          <a class="link">印象证件照</a>
          <a class="link">新闻资讯</a>
          <a class="link">关于我们</a> -->

          <a
            v-for="(item, i) in navNames"
            :key="i"
            :class="{ on: i == curIndex }"
            @click="jumpUrl(i)"
            class="link"
            >{{ item }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "idphoto-mheader",
  props: {},
  data() {
    return {
      showBox: false,
      curIndex: 0,
      curName: "",
      navNames: ["首页", "结婚登记照", "印象证件照", "新闻资讯", "关于我们"],
    };
  },
  mounted() {
    this.curName = this.$route && this.$route.name;
    switch (this.curName) {
      case "m_index":
        this.curIndex = 0;
        break;

      case "m_jiehun":
        this.curIndex = 1;
        break;

      case "m_yinxiang":
        this.curIndex = 2;
        break;

      case "m_zixun":
        this.curIndex = 3;
        break;

      case "m_about":
        this.curIndex = 4;
        break;
    }
    if (this.curName.indexOf("artical") > -1) {
      this.curIndex = 3;
    }
  },
  methods: {
    jumpUrl(idx) {
      //   let index = idx - 1;
      let names = ["m_index", "m_jiehun", "m_yinxiang", "m_zixun", "m_about"];
      console.log(names[idx]);
      this.$router
        .push({
          name: names[idx],
        })
        .catch((err) => {
          err;
        });
    },
    showMenu() {
      this.showBox = !this.showBox;
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/m_app.less";
@import "./m_header.less";
</style>
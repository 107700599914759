<template>
  <div>
    <div>
      <!-- 公共导航 -->
      <idphoto-mheader></idphoto-mheader>

      <div class="content">
        <div class="banner">
          <h1 class="logo">印象证件照</h1>
          <h2>简单易用的专业证件照制作软件</h2>
          <div class="des-img">
            <img src="../../../static/imgs/idphoto/m_yinxiang_tu1@2x_1.png"
                 alt="">
          </div>
          <div class="yx-load market-download hide">
            应用市场下载
          </div>
          <div class="yx-load btn-download" @click="download">
            直接下载
            <!-- <img src="../assets/imgs/m_bt_lijixiazai@2x.png" alt=""> -->
          </div>
          <p class="app-version">版本号：2.4.2</p>
          <!-- <p class="app-version">直接下载安装时可能会有【风险提示】，请继续安装即可</p> -->
        </div>

        <div class="product-box">
          <div class="product-item c">
            <p>多种好证件照规格</p>
            <p>覆盖常用证件照场景</p>
            <img class="img1"
                 src="../../../static/imgs/idphoto/m_yixiang_2@2x.png"
                 alt="">
          </div>
          <div class="product-item c">
            <p>标准的像素尺寸和文件大小</p>
            <img class="img2"
                 src="../../../static/imgs/idphoto/m_yinxiang_tu3@2x.png"
                 alt="">
          </div>
          <div class="product-item c">
            <p>一键智能美颜</p>
            <p>柔美又不失真</p>
            <img class="img3"
                 src="../../../static/imgs/idphoto/m_yixiang_tu4@2x.png"
                 alt="">
          </div>
          <div class="product-item c">
            <p>电子版即时下载</p>
            <p>纸质版包邮到家</p>
            <img class="img3"
                 src="../../../static/imgs/idphoto/m_yinxiang_tu5@2x.png"
                 alt="">
          </div>
        </div>
      </div>

      <!-- 公共底部 -->
      <idphoto-mfooter></idphoto-mfooter>
    </div>

    <!-- 应用市场弹框 不能删 -->
    <div class="dialog-mask"></div>
    <div class="download-dialog">
      <h2>打开应用市场下载 【印象证件照】</h2>
      <div class="download-btns">
        <p>
          <b>推荐</b>
          <span class="market-name w150"></span>
        </p>
        <!-- <span class="market-name">应用宝</span>
            <span class="market-name">百度手机助手</span> -->
        <span class="market-name">魅族应用市场</span>
        <span class="market-name">三星应用市场</span>
        <span class="market-name">vivo应用市场</span>
        <span class="market-name">OPPO应用市场</span>
        <span class="market-name">小米应用市场</span>
        <span class="market-name">华为应用市场</span>
      </div>
      <p>若您无法通过按钮下载app ,您可以选择“直接 下载”，或直接去应用商店搜索下载</p>
      <div class="btn market-btn">稍后安装</div>
    </div>
  </div>
</template>
<script>
import IdphotoMheader from '../../../component/idphoto/m_header/m_header.vue';
import IdphotoMfooter from '../../../component/idphoto/m_footer/m_footer.vue';
export default {
  name: 'm_jiehun',
  components: {
    IdphotoMheader,
    IdphotoMfooter
  },
  data () {
    return {
      yxUrl: {},
      isIOS: false
    }
  },
  mounted () {
    document.title = "印象证件照";
    this.yxUrl = {
      ios: 'https://itunes.apple.com/cn/app/id1445293660',
      android: 'https://oss.puwu.info/idphoto/update/印象证件照.apk'
    };
    let ua = navigator.userAgent.toLowerCase();
    this.isIOS = ua.indexOf('mac os') > -1;
  },
  methods: {
    download () {
      location.href = this.isIOS ? this.yxUrl.ios : this.yxUrl.android;
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/m_app.less";
@import "./m_yinxiang.less";
</style>
<template>
  <div>
    <!-- 公共导航 -->
    <idphoto-header></idphoto-header>

    <div class="banner">
      <div class="banner-content flex_box flex_align_center">
        <div class="left-cont">
          <h1>结婚证件照</h1>
          <h3>全网唯一一家在线制作结婚登记照app</h3>
          <p>支持常规寸照、报名照、签证照、社保照等500种证件照规格</p>
          <div class="download-box flex_box">
            <div class="">
              <img
                class="qrcode"
                src="../../../static/imgs/idphoto/erweima@2x.png"
                alt=""
              />
            </div>
            <div class="">
              <div class="btn-down ios" @click="iosDownload">
                <img src="../../../static/imgs/idphoto/ic_ios@2x.png" alt="" />
                iOS下载
              </div>
              <div class="btn-down anzhuo" @click="anzhuoDownload">
                <img
                  src="../../../static/imgs/idphoto/ic_anzhuo@2x.png"
                  alt=""
                />
                安卓下载
              </div>
            </div>
          </div>
          <div class="banner-privacy">
            <p>开发者：苏州普物科技有限公司</p>
            <p>
              结婚证件照 V1.6.2
              <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;<a
                class="blue"
                href="https://oss.puwu.info/mphoto/web/privacy_marryphoto.html"
                target="_blank"
                >隐私协议</a
              >
            </p>
          </div>
        </div>
        <div class="right-cont">
          <img src="../../../static/imgs/idphoto/shouye_tu1@2x.png" alt="" />
        </div>
      </div>
    </div>

    <div class="product-info-content">
      <div class="product-item flex_box flex_justify_center pink">
        <div class="left">
          <img src="../../../static/imgs/idphoto/jiehun_tu2@2x.png" alt="" />
        </div>
        <div class="right">
          <h1>
            <img
              src="../../../static/imgs/idphoto/yuan1@2x.png"
              alt=""
            />精修结婚登记照
          </h1>
          <h3>Marriage certificate photo</h3>
          <p>百名设计师在线修图</p>
          <p>即使一张普通的合照也能帮你</p>
          <p>制作美美的结婚登记照</p>
          <div class="btn-box">
            <div class="btn-down">立即下载</div>
          </div>
        </div>
      </div>

      <div class="product-item flex_box flex_justify_center light-blue">
        <div class="right">
          <h1>
            <img
              src="../../../static/imgs/idphoto/yuan2@2x.png"
              alt=""
            />常规证件照
          </h1>
          <h3>Regular certificate photo</h3>
          <p>500+种证件照规格</p>
          <p>常规寸照，报名照，社保照等</p>
          <p>让你随时拍摄你需要的证件照</p>
          <div class="btn-box">
            <div class="btn-down">立即下载</div>
          </div>
        </div>
        <div class="left left1">
          <img src="../../../static/imgs/idphoto/jiehun11@2x.png" alt="" />
        </div>
      </div>

      <div class="product-item flex_box flex_justify_center orange">
        <div class="left">
          <img src="../../../static/imgs/idphoto/jiehun_tu4@2x.png" alt="" />
        </div>
        <div class="right">
          <h1>
            <img
              src="../../../static/imgs/idphoto/yuan3@2x.png"
              alt=""
            />手绘动漫形象照
          </h1>
          <h3>Cartoon image</h3>
          <p>专业设计师在线绘制</p>
          <p>画一个不一样的你</p>
          <p>头像也有你想不到的私人定制</p>
          <div class="btn-box">
            <div class="btn-down">立即下载</div>
          </div>
        </div>
      </div>

      <div class="product-item flex_box flex_justify_center blue">
        <div class="right">
          <h1>
            <img
              src="../../../static/imgs/idphoto/yuan4@2x.png"
              alt=""
            />精修正装照
          </h1>
          <h3>Refined formal photo</h3>
          <p>一张好看的正装照</p>
          <p>帮助你提高简历通过率</p>
          <p>打开app 即刻获取好看正装照</p>
          <div class="btn-box">
            <div class="btn-down">立即下载</div>
          </div>
        </div>
        <div class="left left1">
          <img src="../../../static/imgs/idphoto/jiehun_tu5@2x.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 公共底部 -->
    <idphoto-footer></idphoto-footer>

    <common-dialog
      :showDialog="isShowDialog"
      :dialogTitle="dialogTitle"
      :dialogContent="dialogContent"
      :showSingleBtn="showSingleBtn"
      :isAlignLeft="isAlignLeft"
      @rightBtnFn="showDialog"
    >
    </common-dialog>
  </div>
</template>
<script>
import IdphotoHeader from "../../../component/idphoto/header/header.vue";
import IdphotoFooter from "../../../component/idphoto/footer/footer.vue";
import commonDialog from "../../../component/dialog/dialog.vue";
export default {
  name: "jiehun",
  components: {
    IdphotoHeader,
    IdphotoFooter,
    commonDialog,
  },
  data() {
    return {
      isShowDialog: false,
      dialogTitle: "获取权限说明",
      showSingleBtn: true,
      isAlignLeft: true,
      dialogContent: "",
    };
  },
  mounted() {},
  methods: {
    iosDownload() {
      location.href = "https://apps.apple.com/cn/app/id1484296045";
    },
    anzhuoDownload() {
      location.href =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.marryphoto";
    },
    showAuthorityBox() {
      this.isShowDialog = true;
      this.dialogContent =
        "为给您提供更优质的服务，结婚证件照App 需要向您申请以下权限：<br/>1. 相册读写权限： 用于您上传照片制作证件照；2. 相机权限：用于拍摄照片制作证件照；<br/>3. 存储权限： 用于保存制作好的证件照；<br/>4. 推送通知权限：用于接收客服回复的消息；<br/>5. 语音输入权限：用于语音咨询客服问题。";
    },
    showDialog() {
      this.isShowDialog = false;
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/app.less";
@import "./jiehun.less";
</style>
<template>
  <div>

    <!-- 公共导航 -->
    <div v-if="isMobile">
      <idphoto-mheader></idphoto-mheader>
    </div>
    <div v-else>
      <idphoto-header></idphoto-header>
    </div>

    <div class="content">
      <div>
        <p class="title"
           style="line-height:33pt; margin:0pt"><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">《手机拍结婚照》省下去照相馆的钱，还很好看</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">在这个</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">看脸颜值至上</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">的时代，每一对新人的结婚登记照都应该充满满满的仪式感还好困，做朋友</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">圈最好</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">看的</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">崽</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">~</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">现在</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">的网红照相馆</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">尽管拍的好看，但实在太贵，每次按快门，仿佛就消耗了几张人民币，今天小编就来教大家用手机「造」一张合格又好看的结婚登记照。</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="199d1a48-065d-409b-af1b-073f0646c1fc.001.jpeg"
               width="554"
               height="370"
               alt="《手机拍结婚照》省下去照相馆的钱，还很好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt; font-weight:bold">小编讲从以下几个方面来讲哈~</span></p>
        <ul type="disc"
            style="margin:0pt; padding-left:0pt">
          <li style=" color:#222222; font-family:serif; font-size:10pt; margin:0pt 0pt 0pt 9.6pt; padding-left:8.4pt; text-indent:0pt">
            <span style="color:#222222; font-family:微软雅黑; font-size:12pt; font-weight:bold">拍照前的准备事项</span></li>
          <li style=" color:#222222; font-family:serif; font-size:10pt; margin:0pt 0pt 0pt 9.6pt; padding-left:8.4pt; text-indent:0pt">
            <span style="color:#222222; font-family:微软雅黑; font-size:12pt; font-weight:bold">找个干净的「墙」当背景</span></li>
          <li style=" color:#222222; font-family:serif; font-size:10pt; margin:0pt 0pt 0pt 9.6pt; padding-left:8.4pt; text-indent:0pt">
            <span style="color:#222222; font-family:微软雅黑; font-size:12pt; font-weight:bold">修图攻略</span></li>
        </ul>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt; font-weight:bold">拍照前准备事项</span></p>
        <ul type="disc"
            style="margin:0pt; padding-left:0pt">
          <li style=" color:#222222; font-family:serif; font-size:10pt; margin:0pt 0pt 0pt 9.6pt; padding-left:8.4pt; text-indent:0pt">
            <span style="color:#222222; font-family:微软雅黑; font-size:12pt">画个干净一点的淡妆，男生女生尽量穿白衬衫，（做出来效果更好噢）</span>
          </li>
          <li style=" color:#222222; font-family:serif; font-size:10pt; margin:0pt 0pt 0pt 9.6pt; padding-left:8.4pt; text-indent:0pt">
            <span style="color:#222222; font-family:微软雅黑; font-size:12pt">镜头前放松：可以先跟伴侣说说笑笑，放松下来，保持最舒服最自信的状态，</span>
          </li>
          <li style=" color:#222222; font-family:serif; font-size:10pt; margin:0pt 0pt 0pt 9.6pt; padding-left:8.4pt; text-indent:0pt">
            <span style="color:#222222; font-family:微软雅黑; font-size:12pt">手里拿一张白纸当打光板</span></li>
        </ul>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="199d1a48-065d-409b-af1b-073f0646c1fc.002.jpeg"
               width="554"
               height="368"
               alt="《手机拍结婚照》省下去照相馆的钱，还很好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt; font-weight:bold">找个干净的「墙」当背景</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">我们没有照相馆的幕布，那就找个纯色</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">的墙当背景</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">拍摄呀。尽量光线均匀正常，避免出现阴阳脸的情况哈。男女双方都要正面哈。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="199d1a48-065d-409b-af1b-073f0646c1fc.003.jpeg"
               width="554"
               height="370"
               alt="《手机拍结婚照》省下去照相馆的钱，还很好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">如果没有第三个拍摄或者嫌延时拍摄麻烦可以互相给对方拍摄，注意尽量站在同一个地方拍摄，这样光线均匀，不会出现假图的痕迹</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="199d1a48-065d-409b-af1b-073f0646c1fc.004.jpeg"
               width="554"
               height="341"
               alt="《手机拍结婚照》省下去照相馆的钱，还很好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt; font-weight:bold">修图攻略</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">一定要自然，不能美颜过度磨皮过度，这样会失真。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">会修图的小哥哥小姐姐们可以自己在用PS处理。结婚登记照的规范是３５＊５３ｍｍ，背景色尽量用深红色。</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">不会修图小可爱们，重点来啦，</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">我们有数十位精修设计师在线精修证件照，只要你上传一张你们拍好的照片，我们会帮您精修生成一张好看的结婚照，需要纸质</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">版的话</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">还</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">支持包邮到家</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="199d1a48-065d-409b-af1b-073f0646c1fc.005.jpeg"
               width="554"
               height="285"
               alt="《手机拍结婚照》省下去照相馆的钱，还很好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">设计师精修后的照片张这样</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="199d1a48-065d-409b-af1b-073f0646c1fc.006.jpeg"
               width="554"
               height="377"
               alt="《手机拍结婚照》省下去照相馆的钱，还很好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">精修打印的纸质版</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">张这样</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">~</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="199d1a48-065d-409b-af1b-073f0646c1fc.007.jpeg"
               width="554"
               height="415"
               alt="《手机拍结婚照》省下去照相馆的钱，还很好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">有需要的可以试试呀，预祝每个人都能拍一张合格又好看的结婚登记照</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
      </div>
    </div>

    <!-- 公共底部 -->
    <div v-if="isMobile">
      <idphoto-mfooter></idphoto-mfooter>
    </div>
    <div v-else>
      <idphoto-footer></idphoto-footer>
    </div>

  </div>
</template>
<script>
import IdphotoHeader from '../../../../component/idphoto/header/header.vue';
import IdphotoFooter from '../../../../component/idphoto/footer/footer.vue';
import IdphotoMheader from '../../../../component/idphoto/m_header/m_header.vue';
import IdphotoMfooter from '../../../../component/idphoto/m_footer/m_footer.vue';
export default {
  name: 'about',
  components: {
    IdphotoHeader,
    IdphotoFooter,
    IdphotoMheader,
    IdphotoMfooter
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
@import "../../../../styles/idphoto/app.less";
@import "../artical.less";
</style>
<template>
  <div>

    <!-- 公共导航 -->
    <idphoto-mheader></idphoto-mheader>

    <!-- start 内容 -->
    <div class="content">

      <swiper ref="mySwiper"
              :options="swiperOptions">
        <swiper-slide>
          <div class="swiper-slide-wrap yinxiang flex_box flex_align_center flex_justify_center">
            <div class="">
              <h1 class="title">印象证件照</h1>
              <h2>一个好的印象，一个好的开始</h2>
              <div class="qrcode-box flex_box">
                <img class="qrcode"
                     src="../../../static/imgs/idphoto/m_yxerweima@2x.png"
                     alt="">
                <div class="flex-1">
                  <div class="btn-down btn-ios-yx"
                       @click="iosDownload(2)"><img src="../../../static/imgs/idphoto/m_ic_ios@2x_1.png"
                         alt="">iOS下载</div>
                  <div class="btn-down btn-an-yx"
                       @click="anzhuoDownload(2)"><img src="../../../static/imgs/idphoto/m_ic_anzhuo@2x_1.png"
                         alt="">安卓下载</div>
                </div>
              </div>
            </div>
            <img class="banner-img"
                 src="../../../static/imgs/idphoto/m_yinxiang_tu1@2x.png"
                 alt="">
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="swiper-slide-wrap jiehun flex_box flex_align_center flex_justify_center">
            <div class="">
              <h1 class="title">结婚证件照</h1>
              <p>全网唯一支持制作</p>
              <p>结婚登记照、证件照的app</p>
              <div class="qrcode-box flex_box">
                <!--img class="qrcode" src="../assets/imgs/m_jherweima@2x.png" alt=""-->
                <div class="flex-1">
                  <div class="btn-down btn-ios-jh"
                       @click="iosDownload(1)"><img src="../../../static/imgs/idphoto/m_ic_ios@2x.png"
                         alt="">iOS下载</div>
                  <div class="btn-down btn-an-jh"
                       @click="anzhuoDownload(1)"><img src="../../../static/imgs/idphoto/m_ic_anzhuo@2x.png"
                         alt="">安卓下载</div>
                </div>
              </div>
            </div>
            <img class="banner-img"
                 src="../../../static/imgs/idphoto/m_shouye_tu1@2x.png"
                 alt="">
          </div>
        </swiper-slide>

        <div class="swiper-pagination"
             slot="pagination"></div>
      </swiper>

      <div class="product-box">
        <a class="product-item flex_box flex_align_center"
           @click="jumpUrl('m_jiehun')">
          <img src="../../../static/imgs/idphoto/m_logo_jiehun@2x.png"
               alt="">
          <div class="flex_1">
            <h1>结婚证件照</h1>
            <p>全网唯一支持制作结婚登记照、证件照的app</p>
          </div>
          <div class="arrow"></div>
        </a>
        <a class="product-item flex_box flex_align_center"
           @click="jumpUrl('m_yinxiang')">
          <img src="../../../static/imgs/idphoto/m_logo_yinxiang@2x.png"
               alt="">
          <div class="flex_1">
            <h1>印象证件照</h1>
            <p>一个好的印象，一个好的开始</p>
          </div>
          <div class="arrow"></div>
        </a>
      </div>
    </div>
    <!-- end 内容 -->

    <!-- 公共底部 -->
    <idphoto-mfooter></idphoto-mfooter>
  </div>
</template>
<script>
import IdphotoMheader from '../../../component/idphoto/m_header/m_header.vue';
import IdphotoMfooter from '../../../component/idphoto/m_footer/m_footer.vue';
export default {
  name: 'm_index',
  components: {
    IdphotoMheader,
    IdphotoMfooter
  },
  data () {
    return {
      downloadLink: {},
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    document.title = "印象证件照";
    this.swiper.slideTo(3, 1000, false)
    this.downloadLink = {
      jiehun: {
        ios: 'https://apps.apple.com/cn/app/id1484296045',
        android: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.marryphoto'
      },
      yinxiang: {
        ios: 'https://itunes.apple.com/cn/app/id1445293660',
        android: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.idphoto'
      }
    }
  },
  methods: {
    iosDownload (type) {
      if (type == 1) {
        location.href = this.downloadLink.jiehun.ios;
      }
      else {
        location.href = this.downloadLink.yinxiang.ios;
      }
    },
    anzhuoDownload (type) {
      if (type == 1) {
        location.href = this.downloadLink.jiehun.android;
      }
      else {
        location.href = this.downloadLink.yinxiang.android;
      }
    },
    jumpUrl (url) {
      this.$router.push({
        name: url
      }).catch(err => { err })
    }

  }
}
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/m_app.less";
@import "./m_index_pw.less";
</style>
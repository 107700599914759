<template>
  <div>
    <!-- 公用头部 -->
    <photo-header></photo-header>

    <div class="photo-about-wrap">

      <div class="about-pic">
        <div class="phone-show">
          <img src="../../../static/imgs/rephoto/phone/about-pic.png"
               alt="文章资讯背景图">
        </div>
        <div class="pc-show">
          <img src="../../../static/imgs/rephoto/pc/about-pic.jpg"
               alt="文章资讯背景图">
        </div>

        <div class="about-title">
          <h2>联系我们</h2>
          <p>有任何问题都请及时联系我们</p>
          <p class="pc-show">无论你是想了解更多信息，或对我公司感兴趣，或对照片有其他的需求都可以及时联系我们</p>
        </div>
      </div>

      <div class="pc-show">
        <div class="about-contact">
          <div class="contact tel">
            <p>联系电话</p>
            <p>{{data.tel}}</p>
          </div>
          <div class="contact email">
            <p>邮箱</p>
            <p>{{data.email}}</p>
          </div>
          <div class="contact qq">
            <p>QQ</p>
            <p>{{data.qq}}</p>
          </div>
          <div class="contact wx">
            <p>公众号</p>
            <p>{{data.public}}</p>
          </div>
        </div>

        <div class="about-qrcode">
          <img src="../../../static/imgs/rephoto/pc/contact.png"
               alt="官方公众号">
          <p>官方公众号</p>
          <p>快来扫一扫，关注吧</p>
        </div>
      </div>

      <div class="phone-show">
        <h2 class="about-subtitle">
          <span>联系我们</span>
        </h2>
        <div class="about-txt">
          <p>联系电话：{{data.tel}}</p>
          <p>邮箱：{{data.email}}</p>
          <p>公众号：{{data.public}}</p>
          <p>公司地址：{{data.address}}</p>
          <br />
          <p>无论您是想了解更多信息、或对照片有其他的需求都可以及时联系我们</p>
        </div>
      </div>

      <!-- 公用底部 -->
      <photo-footer></photo-footer>
    </div>
</template>
<script>
import data from '../../../static/json/data.json'
import PhotoHeader from '../../../component/rephoto/header/header.vue';
import PhotoFooter from '../../../component/rephoto/footer/footer.vue';
// import PhotoHeader from '../header/header.vue';
// import PhotoFooter from '../footer/footer.vue';
export default {
  name: 'about',
  components: {
    PhotoHeader,
    PhotoFooter,
  },
  data () {
    return {
      data: data
    }
  }
}
</script>
<style lang="less" scoped>
@import "./about.less";
</style>
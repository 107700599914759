<template>
  <div>
    <!-- 公用头部 -->
    <photo-header></photo-header>

    <div class="photo-news-wrap">

      <div class="news-pic">
        <img src="../../../static/imgs/rephoto/pc/news-pic.png"
             alt="文章资讯背景图">
        <span>文章资讯</span>
      </div>

      <div class="news-list-wrap">

        <div class="news-list"
             v-for="(item,index) in newsList"
             :key="index">
          <div class="news-list-pic"
               @click="jumpList()">
            <img :src=item.url
                 alt="">
            <span class="news-praise"></span>
            <span class="news-tip">黑白照片上色</span>
          </div>
          <div class="news-list-title">
            {{item.title}}
          </div>
          <div class="new-list-user">
            <img :src=item.userImg
                 alt="用户头像">
            <span class="name">{{item.name}}</span>
            <span class="praise">{{item.number}}</span>
          </div>
        </div>

      </div>

    </div>

    <!-- 公用底部 -->
    <photo-footer></photo-footer>
  </div>
</template>
<script>
import PhotoHeader from '../../../component/rephoto/header/header.vue';
import PhotoFooter from '../../../component/rephoto/footer/footer.vue';
export default {
  name: 'news',
  components: {
    PhotoHeader,
    PhotoFooter,
  },
  data () {
    return {
      newsList: [{
        "url": require("../../../static/imgs/rephoto/pc/news-temp.png"),
        "userImg": require("../../../static/imgs/rephoto/pc/news-user.png"),
        "title": "黑白照片上色-寻找儿时的记忆",
        "name": "zoeyey周",
        "number": "254",
      }, {
        "url": require("../../../static/imgs/rephoto/pc/news-temp2.png"),
        "userImg": require("../../../static/imgs/rephoto/pc/news-user.png"),
        "title": "黑白照片上色-寻找儿时的记忆",
        "name": "zoeyey周一一",
        "number": "254",
      }, {
        "url": require("../../../static/imgs/rephoto/pc/news-temp.png"),
        "userImg": require("../../../static/imgs/rephoto/pc/news-user.png"),
        "title": "黑白照片上色-寻找儿时的记忆",
        "name": "zoeyey周一一",
        "number": "254",
      }, {
        "url": require("../../../static/imgs/rephoto/pc/news-temp2.png"),
        "userImg": require("../../../static/imgs/rephoto/pc/news-user.png"),
        "title": "黑白照片上色-寻找儿时的记忆",
        "name": "zoeyey周一一",
        "number": "254",
      }, {
        "url": require("../../../static/imgs/rephoto/pc/news-temp.png"),
        "userImg": require("../../../static/imgs/rephoto/pc/news-user.png"),
        "title": "黑白照片上色-寻找儿时的记忆",
        "name": "zoeyey周一一",
        "number": "254",
      }]
    }
  },
  methods: {
    jumpList () {
      this.$router.push({
        name: 'newslist',
      }).catch(err => { err })
    }
  }
}

</script>
<style lang="less" scoped>
@import "./news.less";
</style>
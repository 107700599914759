<!-- App.vue --> 
<template>
  <div class="app-wrap">
    <!-- router-link 定义点击后导航到哪个路径下 -->
    <!-- <router-link to="/home">Home</router-link>
    <router-link to="/about">About</router-link> -->
    <router-link to=""></router-link>
    <!-- 对应的组件内容渲染到router-view中 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted () {
    // this.init();
  },
  beforeMount () {
  },
  data () {
    return {
      title: "印象证件照",
    }
  },
  methods: {
    // init () {
    //   const router = new VueRouter({
    //     routes: [
    //       {
    //         path: '/user/:userId',
    //         name: 'user',
    //         component: User
    //       }
    //     ]
    //   })
    // },
  }
}
</script>
<style lang="less">
@import "./styles/app.less";
</style>
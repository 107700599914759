<template>
  <div class="revideo-wrap">
    <div style="height: 500px;text-align: center;padding-top: 200px;font-size: 20px;" >PDF笔记网站建设中...</div>

    <div class="revideo-footer">
      <div class="pc-show">
        <div class="copyright">
          Copyright@2021苏州普物科技有限公司
          <a href="http://beian.miit.gov.cn" target="_blank"
            >苏ICP备18052115号—3</a>
        </div>
        <div class="addr">
          地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
        </div>
        <div class="contact">
          电话：0512-87816685 &nbsp;&nbsp;&nbsp;&nbsp;
          邮箱：feedback@techtushuo.com
        </div>
      </div>
      <!-- <div class="phone-show">
        联系电话：0512-87816685
        <br />联系邮箱：feedback@techtushuo.com
        <br />联系地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
        <br />Copyright@2020苏州图说科技有限公司
      </div> -->
    </div>
    </div>
</template>
<script>
export default {
  name: "ts_idphoto",
  components: {},
  data() {
    return {
      reNew: {
        oldpic: "",
        newpic: "",
        // oldpic: require("../../../static/imgs/revideo/pic_xiufu.png"),
        // newpic: require("../../../static/imgs/revideo/pic_xiufu2.png"),
        btnLeft: "50%",
        wrapWidth: "100%",
        oldPicWidth: "50%",
        newPicWidth: "50%",
        changeId: "renewId",
      },
      reColor: {
        oldpic: "",
        newpic: "",
        // oldpic: require("../../../static/imgs/revideo/pic_shangse.png"),
        // newpic: require("../../../static/imgs/revideo/pic_shangse2.png"),
        btnLeft: "50%",
        wrapWidth: "100%",
        oldPicWidth: "50%",
        newPicWidth: "50%",
        changeId: "recolorId",
      },

      flags: false,
      position: {
        x: 0,
        y: 0,
      },
      nx: 0,
      dx: 0,
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./pdfnote.less";
</style>
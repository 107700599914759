<template>
  <div class="revideo-wrap">

    <div class="revideo-banner">
      <div class="revideo-banner-left">
        <div class="revideo-banner-logo"></div>
        <div class="revideo-banner-txt">
          百鸟客服
        </div>
      </div>
    </div>
    <div class="revideo-top-wrap">
      <div class="revideo-top">
        <div class="revideo-top-pic"></div>
        <div class="revideo-top-txt">
          <div class="revideo-top-title">纵享高效愉悦的聊天模式</div>
          <div class="revideo-pros">
            <div class="revideo-pros-list">全渠道一键集成</div>
            <div class="revideo-pros-list">操作简单，上手容易</div>
            <div class="revideo-pros-list">工作台个性自定义</div>
          </div>
        </div>
      </div>
    </div>

    <div class="revideo-content-wrap">
      <div class="revideo-content-list bg2">
        <div class="revideo-list-main">
          <div class="revideo-list-txt">
            <div class="revideo-list-title">营销机器人</div>
            <div class="revideo-list-pro">
              自动化营销，自动获线<br/>个性化对话流程
            </div>
          </div>

          <div class="revideo-list-pic">   
          </div>
        </div>
      </div>

      <div class="revideo-content-list bg3">
        <div class="revideo-list-main">
          <div class="revideo-list-pic bg3">   
          </div>
          <div class="revideo-list-txt fr">
            <div class="revideo-list-title">工单系统</div>
            <div class="revideo-list-pro">
              团队协作支持<br/>创造卓越非凡的服务体验
            </div>
          </div>
      </div>
      
    </div>

    <div class="revideo-footer">
      <div class="copyright">
        Copyright@2020苏州普物科技有限公司
        <a href="http://beian.miit.gov.cn" target="_blank"
          >苏ICP备18052115号-7</a>
      </div>
      <div class="addr">
        地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
      </div>
      <div class="contact">
        电话：0512-87816685 &nbsp;&nbsp;&nbsp;&nbsp;
        邮箱：feedback@techtushuo.com
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pw_message",
  components: {},
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    
  },
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./pw_message.less";
</style>
<template>
  <div class="content">
    <!-- 公共导航 -->
    <idphoto-mheader></idphoto-mheader>

    <div class="content">
      <div class="banner">
        <h1 class="logo">结婚证件照</h1>
        <h2>全网唯一一家支持制作</h2>
        <h2>结婚登记照、证件照的app</h2>
        <div class="des-img">
          <img
            src="../../../static/imgs/idphoto/m_jiehun_shouye_tu1@2x.png"
            alt=""
          />
        </div>
        <div class="btn-download" @click="download">
          <img
            src="../../../static/imgs/idphoto/m_bt_lijixiazai@2x_jiehun.png"
            alt=""
          />
        </div>
        <p class="app-version">版本号：1.6.8</p>
      </div>

      <div class="product-box">
        <div class="product-item">
          <img
            src="../../../static/imgs/idphoto/m_jiehundengji_banner@2x.png"
            alt=""
          />
          <div class="des des-1 flex_box flex_column flex_justify_center">
            <h3>结婚登记照</h3>
            <div class="flex_box flex_align_center">
              <p class="flex_1">专业设计师，合格又好看</p>
              <img
                class="btn-down"
                @click="download"
                src="../../../static/imgs/idphoto/m_lijipaishe_anniu@2x.png"
              />
            </div>
          </div>
        </div>
        <div class="product-item">
          <img
            src="../../../static/imgs/idphoto/m_changguizhengjianzhaobanner@2x.png"
            alt=""
          />
          <div class="des des-2 flex_box flex_column flex_justify_center">
            <h3>常规证件照</h3>
            <div class="flex_box flex_align_center">
              <p class="flex_1">寸照、社保证、考试照等500+种证件照</p>
              <img
                class="btn-down"
                @click="download"
                src="../../../static/imgs/idphoto/m_lijipaishe_anniu@2x.png"
              />
            </div>
          </div>
        </div>
        <div class="product-item">
          <img
            src="../../../static/imgs/idphoto/m_shouhuizhaobanner@2x.png"
            alt=""
          />
          <div class="des des-3 flex_box flex_column flex_justify_center">
            <h3>手绘结婚登记照/情侣照</h3>
            <div class="flex_box flex_align_center">
              <p class="flex_1">专业设计师操刀绘制，可爱不失真</p>
              <img
                class="btn-down"
                @click="download"
                src="../../../static/imgs/idphoto/m_lijipaishe_anniu@2x.png"
              />
            </div>
          </div>
        </div>
        <div class="product-item">
          <img src="../../../static/imgs/idphoto/m_aaabanner@2x.png" alt="" />
          <div class="des des-4 flex_box flex_column flex_justify_center">
            <h3>精修证件照</h3>
            <div class="flex_box flex_align_center">
              <p class="flex_1">人工精修，拍摄制作合格好看的正装照</p>
              <img
                class="btn-down"
                @click="download"
                src="../../../static/imgs/idphoto/m_lijipaishe_anniu@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 公共底部 -->
    <idphoto-mfooter></idphoto-mfooter>
  </div>
</template>
<script>
import IdphotoMheader from "../../../component/idphoto/m_header/m_header.vue";
import IdphotoMfooter from "../../../component/idphoto/m_footer/m_footer.vue";
export default {
  name: "m_jiehun",
  components: {
    IdphotoMheader,
    IdphotoMfooter,
  },
  data() {
    return {
      jiehunUrl: {},
      isIOS: false,
    };
  },
  mounted() {
    console.log(111111);
    this.jiehunUrl = {
      ios: "https://apps.apple.com/cn/app/id1484296045",
      android: "https://oss.puwu.info/mphoto/结婚证件照.apk",
    };
    let ua = navigator.userAgent.toLowerCase();
    this.isIOS = ua.indexOf("mac os") > -1;

    document.title = "结婚证件照";
  },
  methods: {
    download() {
      location.href = this.isIOS ? this.jiehunUrl.ios : this.jiehunUrl.android;
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/m_app.less";
@import "./m_jiehun.less";
</style>
<template>
  <div class="taner-wrap">
    <div class="taner-banner">
      <div class="taner-logo">
        <img src="../../static/imgs/taner/logo.png" alt="" />
      </div>
    </div>
    <div class="tanner-top">
      <div class="tanner-top-main">
        <div class="tanner-top-left">
          <div class="taner-top-txt">全网最全摆摊资讯平台</div>
          <div class="tanner-top-tip"></div>
        </div>
        <div class="tanner-top-pic"></div>
      </div>
    </div>
    <div class="tanner-main">
      <div class="tanner-main-box">
        <div class="tanner-main-pic tu1"></div>
        <div class="tanner-main-content">
          <div class="tanner-main-title">
            摆摊干货<br />最全摆摊经验教你赚钱
          </div>
          <div class="tanner-main-txt">
            摊主真实经验，官方筛选优质内容摆摊干货，
            让你花最少的时间筛选适合自己的摆摊思路
          </div>
        </div>
      </div>

      <div class="tanner-main-box">
        <div class="tanner-main-content">
          <div class="tanner-main-title">全国各地摆摊最新政策 快速了解</div>
          <div class="tanner-main-txt">
            实时更新全国各地摆摊最新政策，合法文 明摆摊安心摆摊，安心赚钱
          </div>
        </div>
        <div class="tanner-main-pic tu2"></div>
      </div>

      <div class="tanner-main-box">
        <div class="tanner-main-pic tu3"></div>
        <div class="tanner-main-content">
          <div class="tanner-main-title">内容创作 摆摊经验心得分享交流</div>
          <div class="tanner-main-txt">
            发表摆摊经验，为更多想要摆摊的用户提供帮助；
            发表摆摊心得，便于与同行交流，优化摆摊方法
          </div>
        </div>
      </div>
    </div>
    <div class="taner-footer">
      <div class="copyright">
        Copyright@2020苏州普物科技有限公司
        <a href="http://beian.miit.gov.cn" target="_blank"
          >苏ICP备18052115号—1</a
        >
      </div>
      <div class="addr">
        地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
      </div>
      <div class="contact">
        电话：0512-87816685 &nbsp;&nbsp;&nbsp;&nbsp;
        邮箱：feedback@techtushuo.com
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ts_idphoto",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./taner.less";
</style>
<template>
  <div>
    <!-- 公共导航 -->
    <idphoto-header></idphoto-header>

    <div class="banner">
      <div class="banner-content flex_box flex_align_center">
        <div class="left-cont">
          <h1>印象证件照</h1>
          <h3>简单易用的专业证件照制作软件</h3>
          <div class="download-box flex_box">
            <div class="">
              <img
                class="qrcode"
                src="../../../static/imgs/idphoto/yxQRCode@2x.png"
                alt=""
              />
            </div>
            <div class="">
              <div class="btn-down ios" @click="iosDownload">
                <img
                  src="../../../static/imgs/idphoto/ic_ios@2x_1.png"
                  alt=""
                />
                iOS下载
              </div>
              <div class="btn-down anzhuo" @click="anzhuoDownload">
                <img
                  src="../../../static/imgs/idphoto/ic_anzhuo@2x_1.png"
                  alt=""
                />
                安卓下载
              </div>
            </div>
          </div>
          <div class="banner-privacy">
            <p>开发者：苏州普物科技有限公司</p>
            <p>
              印象证件照 V2.4.3
              <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;<a
                class="blue"
                href="https://oss.puwu.info/idphoto/web/privacy.html"
                target="_blank"
                >隐私协议</a
              >
            </p>
          </div>
        </div>
        <div class="right-cont">
          <img src="../../../static/imgs/idphoto/yinxiang_tu1@2x.png" alt="" />
        </div>
      </div>
    </div>

    <div class="product-info-content">
      <div class="product-item flex_box flex_justify_center">
        <div class="left mr mt">
          <h1>多种规格覆盖常用证件照场景</h1>
          <p>
            一寸照、两寸照、宝宝证、学生证、工作证、登记照各种资格证、各种签证等等统统都支持。
          </p>
        </div>
        <div class="right">
          <img
            class="p-img"
            src="../../../static/imgs/idphoto/yixiang_2@2x.png"
            alt=""
          />
        </div>
      </div>

      <div class="product-item flex_box flex_justify_center">
        <div class="right">
          <img
            class="p-img"
            src="../../../static/imgs/idphoto/yinxiang_tu3@2x.png"
            alt=""
          />
        </div>
        <div class="left ml mt">
          <h1>标准的像素尺寸和文件大小</h1>
          <p>
            标准的像素尺寸，ppi分辨率和文件大小，无论是电子版还是纸质版都能保证不多不少一个像素。
          </p>
        </div>
      </div>

      <div class="product-item flex_box flex_justify_center">
        <div class="left mr mt">
          <h1>一键智能美颜，柔美又不失真</h1>
          <p>
            一键设置美颜等级，实时查看美颜效果，AI自然美肤美颜，给你美美的又不失真的最美证件照。
          </p>
        </div>
        <div class="right">
          <img
            class="p-img"
            src="../../../static/imgs/idphoto/yixiang_tu4@2x.png"
            alt=""
          />
        </div>
      </div>

      <div class="product-item flex_box flex_justify_center">
        <div class="right">
          <img
            class="p-img"
            src="../../../static/imgs/idphoto/yinxiang_tu5@2x.png"
            alt=""
          />
        </div>
        <div class="left ml mt">
          <h1>电子版即时下载，纸质版包邮</h1>
          <p>
            即时下载标准尺寸、大小的电子版。精美的纸质版采用专业相纸冲印，免费包邮送到您家。
          </p>
        </div>
      </div>
    </div>

    <!-- 公共底部 -->
    <idphoto-footer></idphoto-footer>

    <common-dialog
      :showDialog="isShowDialog"
      :dialogTitle="dialogTitle"
      :dialogContent="dialogContent"
      :showSingleBtn="showSingleBtn"
      :isAlignLeft="isAlignLeft"
      @rightBtnFn="showDialog"
    >
    </common-dialog>
  </div>
</template>
<script>
import IdphotoHeader from "../../../component/idphoto/header/header.vue";
import IdphotoFooter from "../../../component/idphoto/footer/footer.vue";
import commonDialog from "../../../component/dialog/dialog.vue";
export default {
  name: "jiehun",
  components: {
    IdphotoHeader,
    IdphotoFooter,
    commonDialog,
  },
  data() {
    return {
      isShowDialog: false,
      dialogTitle: "获取权限说明",
      showSingleBtn: true,
      isAlignLeft: true,
      dialogContent: "",
    };
  },
  mounted() {
    // document.title = "印象证件照";
  },
  methods: {
    iosDownload() {
      location.href = "https://itunes.apple.com/cn/app/id1445293660";
    },
    anzhuoDownload() {
      location.href =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.idphoto";
    },
    showAuthorityBox() {
      this.isShowDialog = true;
      this.dialogContent =
        "为给您提供更优质的服务，印象证件照App 需要向您申请以下权限：<br/>1. 相册读写权限： 用于您上传照片制作证件照；<br/>2. 相机权限：用于拍摄照片制作证件照；<br/>3. 存储权限： 用于保存制作好的证件照；<br/>4. 推送通知权限：用于接收客服回复的消息；<br/>5. 语音输入权限：用于语音咨询客服问题。";
    },
    showDialog() {
      this.isShowDialog = false;
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/app.less";
@import "./yinxiang.less";
</style>
<template>
  <div class="photo-block-wrap">
    <div class="photo-block">
      <div class="photo-block-right">
        <div class="photo-title">10秒智能翻新模糊人像</div>
        <div class="photo-subtitle">一键翻新，立等可取</div>
        <div class="photo-intro">适合恢复，老照片、低像素、网络模糊<br class="pc-show" />图片中五官模糊的照片</div>
      </div>
      <div class="photo-block-left">
        <div class="photo-pic-top"
             id="pic1Wrap">
          <div class="photo-old"
               :style="{width: reNew.oldPicWidth}"
               @click="clickReNew">
            <img :src=reNew.bigPics[reNew.index].old
                 :style="{width: reNew.wrapWidth+'px'}"
                 alt="">
            <span>翻新前</span>
          </div>
          <div class="photo-change pc-show"
               :style="{left: reNew.btnLeft}"
               @mousedown="moveReNew">
          </div>
          <div class="photo-change phone-show"
               :style="{left: reNew.btnLeft}"
               id="reNewId"
               @touchstart="down"
               @touchmove="moveReNewMobile"
               @touchend="end">
          </div>
          <div class="photo-new"
               :style="{width: reNew.newPicWidth}"
               @click="clickReNew">
            <img :src=reNew.bigPics[reNew.index].new
                 :style="{width: reNew.wrapWidth+'px'}"
                 alt="">
            <span>翻新后</span>
          </div>
        </div>
        <div class="photo-pic-bar">
          <div class="pic-details"
               v-for="(item,index) in reNew.smallPics"
               :key="index"
               :class="{'on':reNew.index == index}"
               @click="clickSmallPic(1,index)">
            <img :src=item.url
                 alt="">
            <span class="low">{{item.title}}</span>
          </div>
        </div>

      </div>
      <div class="edit-btn phone-show"
           @click="download">
        立即修复
      </div>
      <div class="home-slide phone-show gray">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home-renew',
  props: {
    reNew: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      isIOS: false,
      iosUrl: "https://apps.apple.com/cn/app/id1497453325",
      anzhuoApk: "https://oss.puwu.info/rephoto/update/照片修复.apk",
    }
  },
  mounted () {
    let ua = navigator.userAgent.toLowerCase();
    this.isIOS = ua.indexOf('mac os') > -1;
  },
  methods: {
    clickReNew (e) {
      this.$emit('clickReNew', e);
    },
    moveReNew (e) {
      this.$emit('moveReNew', e);
    },
    moveReNewMobile (e) {
      this.$emit('moveReNewMobile', e);
    },
    clickSmallPic (type, index) {
      this.$emit('clickSmallPic', type, index);
    },
    down (e) {
      this.$emit('down', e, "reNewId");
    },
    end (e) {
      this.$emit('end', e);
    },
    download () {
      location.href = this.isIOS ? this.iosUrl : this.anzhuoApk;
    }

  }
}
</script>
<template>
  <div class="vr-wrap">
    <div class="vr-header">
      <div class="vr-title">
        <div class="vr-title-name">视频照片恢复</div>
        <div class="vr-title-anchor">
          <span
            v-for="(item, idx) in navList"
            :key="idx"
            @click="jumpAnchor(item.anchor)"
            >{{ item.name }}</span
          >
        </div>
      </div>

      <div class="vr-header-main">
        <div class="vr-header-txt">
          <div class="vr-txt1">WELCOME</div>
          <div class="vr-txt2">视频照片恢复</div>
          <div class="vr-txt3">丢失了照片/视频？小智帮你快速找回</div>
          <div class="vr-qrcode"></div>
          <div class="vr-download" @click="anzhuoDown">安卓下载</div>
        </div>
        <div class="vr-header-pic">
          <img src="../../../static/imgs/vr/vr-pic1.png" alt="" />
        </div>
      </div>
    </div>

    <div class="vr-pro-intro" id="proIntro">
      <div class="fz25">产品介绍</div>
      <div class="fz18">Product introduction</div>
    </div>

    <div class="vr-block-list">
      <div class="vr-block">
        <div class="vr-intro-main">
          <div class="vr-intro-title">照片修复</div>
          <div class="vr-intro-txt">
            快速扫描手机中所有照片<br />快捷找回手机误删，丢失的照片<br />
            识别人脸功能，快速筛选人像
          </div>
          <div class="vr-intro-btn" @click="anzhuoDown">立即下载</div>
        </div>
        <div class="vr-intro-pic">
          <img src="../../../static/imgs/vr/vr-pic22.png" alt="" />
        </div>
      </div>
      <div class="vr-block">
        <div class="vr-intro-pic">
          <img src="../../../static/imgs/vr/vr-pic33.png" alt="" />
        </div>
        <div class="vr-intro-main">
          <div class="vr-intro-title">视频恢复</div>
          <div class="vr-intro-txt">
            快速扫描手机中所有视频<br />损坏视频可修复转化为可播放视频<br />
            本机操作，安全放心
          </div>
          <div class="vr-intro-btn" @click="anzhuoDown">立即下载</div>
        </div>
      </div>
      <div class="vr-block">
        <div class="vr-intro-main">
          <div class="vr-intro-title">
            <span>深度照片恢复</span>
            <em>独家</em>
          </div>
          <div class="vr-intro-txt">
            深度扫描手机中所有照片<br />
            全面找回手机误删，丢失的照片<br />
            识别人脸算法，快速筛选人像
          </div>
          <div class="vr-intro-btn" @click="anzhuoDown">立即下载</div>
        </div>
        <div class="vr-intro-pic">
          <img src="../../../static/imgs/vr/vr-pic44.png" alt="" />
        </div>
      </div>
      <div class="vr-block pb">
        <div class="vr-intro-pic">
          <img src="../../../static/imgs/vr/vr-pic55.png" alt="" />
        </div>
        <div class="vr-intro-main">
          <div class="vr-intro-title">
            <span>深度视频恢复</span>
            <em>独家</em>
          </div>
          <div class="vr-intro-txt">
            深度挖掘手机上被删除的视频<br />本机自助操作，隐私安全<br />全面恢复所有视频
          </div>
          <div class="vr-intro-btn" @click="anzhuoDown">立即下载</div>
        </div>
      </div>
    </div>

    <div class="vr-pro-intro" id="advantage">
      <div class="fz25">我们的优势</div>
      <div class="fz18">Product advantages</div>
    </div>

    <div class="vr-block-list">
      <div class="vr-advant-wrap">
        <div class="vr-advant">
          <img src="../../../static/imgs/vr/vr-pic6.png" alt="" />
          <div class="vr-advant-title">专业恢复</div>
          <div class="vr-advant-txt">
            数十名资深程序员研发团<br />队，保证恢复质量
          </div>
        </div>
        <div class="vr-advant">
          <img src="../../../static/imgs/vr/vr-pic7.png" alt="" />
          <div class="vr-advant-title">人工智能</div>
          <div class="vr-advant-txt">
            人工智能算法成熟，帮助<br />
            你有效找回视频照片
          </div>
        </div>
        <div class="vr-advant">
          <img src="../../../static/imgs/vr/vr-pic8.png" alt="" />
          <div class="vr-advant-title">安全可靠</div>
          <div class="vr-advant-txt">
            手机自助找回，保证你视<br />频照片隐私不会泄漏
          </div>
        </div>
      </div>
    </div>

    <div class="vr-user-evaluate" id="evaluate">
      <div class="vr-pro-intro">
        <div class="fz25">用户评价</div>
        <div class="fz18">User evaluation</div>
      </div>
      <div
        class="vr-kefu tag-read"
        data-clipboard-text="13738817358"
        @click="showKefuTip"
      ></div>
      <div class="vr-slider-wrap">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide>
            <div class="vr-slider">
              <div class="slider-pic">
                <img src="../../../static/imgs/vr/vr-header1.png" alt="" />
              </div>
              <div class="slider-name">小青***</div>
              <div class="slider-txt">
                照片不小心删除了。我搜到这个 软件试了，找到我删除的照片了，
                这对我来说非常的重要。我感谢 这个软件帮我恢复了删除的照片。
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="vr-slider">
              <div class="slider-pic">
                <img src="../../../static/imgs/vr/vr-header2.png" alt="" />
              </div>
              <div class="slider-name">兔子***</div>
              <div class="slider-txt">
                挺好的，先免费预览恢复的记录， 然后再付钱的，比那些啥都没见
                着就要先付钱的靠谱多了。
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="vr-slider">
              <div class="slider-pic">
                <img src="../../../static/imgs/vr/vr-header3.png" alt="" />
              </div>
              <div class="slider-name">时光***</div>
              <div class="slider-txt">
                关键的照片部分都成功恢复了，还 有些视频内容还没有能打开，工作
                人员协助下，也找回了。
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div class="vr-footer-wrap">
      <div class="vr-footer">
        <div class="vr-footer-left">
          <div class="vr-footer-qrcode">
            <img src="../../../static/imgs/vr/qrcode.png" alt="" />
          </div>
          <div>扫码下载视频照片恢复app</div>
        </div>
        <div class="vr-footer-main">
          <div class="vr-footer-title">视频照片恢复—AI智能一键恢复</div>
          <div class="vr-footer-txt">
            <!-- 苏州普物科技有限公司<br />地址：苏州工业园区脉山龙大厦1号楼415室<br />邮箱：feedback@techpuwu&nbsp;电话：0512—87816685<br />copyright@2018苏州普物科技有限公司 <a href="http://beian.miit.gov.cn"
               target="_blank">苏ICP备18052115号—1</a> -->
            <p>
              开发者：苏州普物科技有限公司
              <a href="http://beian.miit.gov.cn" target="_blank"
                >苏ICP备18052115号—5</a
              >
            </p>
            <p>
              视频照片恢复 V2.1.8
              <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;<a
                class="blue"
                href="https://oss.puwu.info/restore/web/restore_private.html"
                target="_blank"
                >隐私协议</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <common-dialog
      :showDialog="isShowDialog"
      :dialogTitle="dialogTitle"
      :isAlignLeft="isAlignLeft"
      :dialogContent="dialogContent"
      :showSingleBtn="showSingleBtn"
      @rightBtnFn="rightBtnFn"
    >
    </common-dialog>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import commonDialog from "../../../component/dialog/dialog.vue";
export default {
  name: "video_restore",
  components: {
    commonDialog,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    let that = this;
    return {
      isShowDialog: false,
      dialogContent: "",
      showSingleBtn: true,
      isAlignLeft: false,
      dialogTitle: "温馨提示",

      navList: [
        {
          anchor: "index",
          name: "首页",
        },
        {
          anchor: "proIntro",
          name: "产品介绍",
        },
        {
          anchor: "advantage",
          name: "产品优势",
        },
        {
          anchor: "evaluate",
          name: "用户评价",
        },
      ],

      swiperOptions: {
        autoplay: {
          delay: 3000,
        },
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 30, // 角度调整
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false, //是否开启阴影
        },
      },
    };
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    if (/mobile|android|iphone|ipad|phone/i.test(ua)) {
      this.$router
        .push({
          name: "m_video_restore",
        })
        .catch((err) => {
          err;
        });
    }
  },
  methods: {
    showKefuTip() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        // alert('微信号复制成功，快去添加微信客服吧')
        this.isShowDialog = true;
        this.isAlignLeft = false;
        this.dialogTitle = "温馨提示";
        this.dialogContent = "微信账号已复制到剪贴板，快去添加客服咨询吧";
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.isShowDialog = true;
        this.isAlignLeft = false;
        this.dialogTitle = "温馨提示";
        this.dialogContent = "该浏览器不支持自动复制";
        // alert('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy();
        // e.preventDefault();
      });
    },
    rightBtnFn() {
      this.isShowDialog = false;
    },
    anzhuoDown() {
      //   location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.magiclogo";
      window.open("https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.restore");
    },
    jumpAnchor(anchor) {
      location.href = "#" + anchor;
    },
    showAuthorityBox() {
      this.dialogTitle = "获取权限说明";
      this.isShowDialog = true;
      this.isAlignLeft = true;
      this.dialogContent =
        "为给您提供更优质的服务，视频照片恢复App 需要向您申请以下权限：<br/>1. 相册读写权限： 用于查找您手机里的照片/视频；<br/>2. 存储权限： 用于保存恢复成功的照片/视频；<br/>3. 推送通知权限：用于接收客服回复的消息；<br/>4. 语音输入权限：用于语音咨询客服问题。";
    },
  },
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./vr_index.less";
</style>
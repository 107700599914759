<template>
  <div class="home-wrap"
       @mousedown="stopImgDrag">
    <!-- 公用头部 -->
    <photo-header></photo-header>

    <!-- <slider ref="slider"
            :options="options"
            @slide='slide'
            @tap='onTap'
            @init='onInit'> -->

    <!-- 主体内容 start -->
    <!-- <slideritem> -->
    <home-top></home-top>
    <!-- </slideritem> -->

    <!-- 10秒智能翻新 -->
    <!-- <slideritem> -->
    <home-renew :reNew="reNew"
                @clickReNew="clickReNew"
                @down="down"
                @end="end"
                @moveReNewMobile="moveReNewMobile"
                @clickSmallPic="clickSmallPic"></home-renew>
    <!-- </slideritem> -->

    <!-- 人工精修破损老照片 -->
    <!-- <slideritem> -->
    <home-refine :reFine="reFine"
                 @clickReFine="clickReFine"
                 @down="down"
                 @end="end"
                 @moveReFineMobile="moveReFineMobile"
                 @clickSmallPic="clickSmallPic"
                 @copy="copy"></home-refine>
    <!-- </slideritem> -->

    <!-- 手机误删照片恢复 -->
    <!-- <slideritem> -->
    <home-redata></home-redata>
    <!-- </slideritem> -->

    <!-- 人像变年轻/精绘婚纱照 -->
    <!-- <slideritem> -->
    <div class="photo-block-wrap white">
      <div class="photo-block">
        <div class="photo-block-right">
          <div class="photo-title">人像变年轻/精绘婚纱照</div>
          <!-- <div class="photo-subtitle">修复师帮你恢复你最美的样子</div> -->
          <div class="photo-subtitle">顶级修复师操刀，恢复你最美的样子</div>
          <div class="photo-intro">人物变年轻，找寻最好年纪的自己</div>
          <div class="photo-intro">精修婚纱照，给父母一份特别的礼物</div>
          <div class="photo-erweima pc-show">
            <div class="erweima">
              <img src="../../../static/imgs/rephoto/pc/design.jpg"
                   alt="">
            </div>
          </div>
          <p class="tel pc-show">修复师微信<br />客服电话：{{data.tel}}</p>
        </div>
        <!-- :autoplay="reYoung.autoPlay"  muted-->
        <div class="photo-block-left">
          <div class="photo-pic-top">
            <div v-for="(item,index) in reYoung.bigvideo"
                 :key="index"
                 v-show="item.show">
              <video :id="'videoPlay'+index"
                     :src="item.url"
                     controls="controls"
                     loop="loop">
              </video>
            </div>
          </div>
          <div class="photo-pic-bar">
            <div class="pic-details video"
                 v-for="(item,index) in reYoung.smallVideo"
                 :key="index"
                 @click="clickSmallPic(3,index)">
              <img :src=item.url
                   alt="">
            </div>
          </div>
        </div>
        <div class="photo-contact phone-show">
          <div class="contact-title">立即预约修复</div>
          <div class="contact-main">
            <!-- <div class="contact-type">
                  <p class="wx">微信预约</p>
                  <p>长按复制添加</p>
                  <button class="tag-read"
                          data-clipboard-text="13738817358"
                          @click="copy">
                </div> -->
            <button type="button"
                    class="contact-type tag-read"
                    data-clipboard-text="13738817358"
                    @click="copy">微信预约<br />点击复制微信号</button>
            <div class="contact-type">
              <a href="tel:051287816685">
                <p class="phone">点击拨打</p>
                <p>{{data.breakTel}}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </slideritem> -->

    <!-- </slider> -->
    <!-- 主体内容 end -->
    <div class="m_dialog_mask"
         v-show="mDialog.show"></div>
    <div class="m_dialog"
         v-show="mDialog.show">

      <div class="m_dialog-main">
        <p class="fs15">{{mDialog.title}}</p>
        <div v-show="mDialog.showTxt">
          <p class="fs13">立即打开微信</p>
          <p class="fs13">添加修复师去修复照片吧</p>
        </div>
      </div>

      <div class="dialog_btn"
           @click="hideDialog">
        知道了
      </div>
    </div>

    <!-- 公用底部 -->
    <photo-footer :showFooter="showFooter"></photo-footer>

  </div>
</template>

<script>
import data from '../../../static/json/data.json'
import Clipboard from 'clipboard';
import HomeTop from './home-top.vue';
import HomeRenew from './home-renew.vue';
import HomeRefine from './home-refine.vue';
import HomeRedata from './home-redata.vue';
import PhotoHeader from '../../../component/rephoto/header/header.vue';
import PhotoFooter from '../../../component/rephoto/footer/footer.vue';
// import PhotoHeader from '../header/header.vue';
// import PhotoFooter from '../footer/footer.vue';
// import { slider, slideritem } from 'vue-concise-slider'
export default {
  name: 'home',
  components: {
    HomeTop,
    HomeRenew,
    HomeRefine,
    HomeRedata,
    PhotoHeader,
    PhotoFooter,
    // slider,
    // slideritem
  },
  data () {
    return {
      data: data,
      //Slider configuration [obj]
      options: {
        // 当前页码
        currentPage: 0,
        // 方向设置，默认为水平滚动('horizontal'),可设置为垂直滚动('vertical')
        direction: 'vertical',
        // 切换效果 默认为滚动滑动('slide'),可设置为渐变滑动('fade'),旋转滚动('coverflow')
        effect: 'slide',
        // 分页是否显示
        pagination: false,
        // 阻止touch事件触发时，整个页面的滚动
        preventDocumentMove: true,
      },

      videoUrl: require("../../../static/imgs/rephoto/young1.mp4"),
      // 翻新
      reNew: {
        index: 0,
        btnLeft: '50%',
        wrapWidth: "325",
        oldPicWidth: '50%',
        newPicWidth: '50%',
        bigPics: [{
          "old": require("../../../static/imgs/rephoto/pc/renew4_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/renew4_new.jpg")
        }, {
          "old": require("../../../static/imgs/rephoto/pc/renew2_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/renew2_new.jpg")
        }, {
          "old": require("../../../static/imgs/rephoto/pc/renew3_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/renew3_new.jpg")
        }],
        smallPics: [{
          "title": "低分辨率示例",
          "url": require("../../../static/imgs/rephoto/pc/renew4_new.jpg"),
        }, {
          "title": "模糊照片示例",
          "url": require("../../../static/imgs/rephoto/pc/renew2_new.jpg"),
        }, {
          "title": "旧照片示例",
          "url": require("../../../static/imgs/rephoto/pc/renew3_new.jpg"),
        }]
      },
      // 精修
      reFine: {
        index: 0,
        btnLeft: '50%',
        wrapWidth: "325",
        oldPicWidth: '50%',
        newPicWidth: '50%',
        txt: ["小众选择", "用户热推", "修复师力荐"],
        bigPics: [{
          "old": require("../../../static/imgs/rephoto/pc/low_refine_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/low_refine_new.jpg")
        }, {
          "old": require("../../../static/imgs/rephoto/pc/middle_refine_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/middle_refine_new.jpg")
        }, {
          "old": require("../../../static/imgs/rephoto/pc/high_refine_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/high_refine_new.jpg")
        }],
        smallPics: [{
          "title": "初级修复",
          "url": require("../../../static/imgs/rephoto/pc/low_refine_new.jpg"),
        }, {
          "title": "中级修复",
          "url": require("../../../static/imgs/rephoto/pc/middle_refine_new.jpg"),
        }, {
          "title": "高级修复",
          "url": require("../../../static/imgs/rephoto/pc/high_refine_new.jpg"),
        }]
      },
      // 变年轻
      reYoung: {
        index: 0,
        autoPlay: "",
        bigvideo: [{
          "show": true,
          "url": require("../../../static/imgs/rephoto/young1.mp4")
        }, {
          "show": false,
          "url": require("../../../static/imgs/rephoto/young2.mp4")
        }, {
          "show": false,
          "url": require("../../../static/imgs/rephoto/young3.mp4")
        }],
        smallVideo: [{
          "url": require("../../../static/imgs/rephoto/pc/home_video1.png"),
        }, {
          "url": require("../../../static/imgs/rephoto/pc/home_video2.png"),
        }, {
          "url": require("../../../static/imgs/rephoto/pc/home_video3.png"),
        }]
      },

      flags: false,
      position: {
        x: 0,
        y: 0
      },
      nx: 0,
      dx: 0,

      showFooter: true,
      showVideo: true,
      mDialog: {
        show: false,
        title: '复制成功',
        showTxt: true
      },
    }
  },
  mounted () {
    // 初始化翻新拖拽按钮尺寸
    let reNewObj = this.initBtnPos('pic1Wrap');
    this.reNew.wrapWidth = reNewObj.width;
    // this.reNew.btnLeft = reNewObj.left;

    // 初始化精修拖拽按钮尺寸
    let reFineObj = this.initBtnPos('pic2Wrap');
    this.reFine.wrapWidth = reFineObj.width;
    // this.reFine.btnLeft = reFineObj.left;

    // if (this._isMobile()) {
    //     document.getElementById('box').style.overflow = "hidden";
    // } else {
    //   //   this.showFooter = true;
    //   //   document.getElementById('box').addEventListener('touchmove', function (event) {
    //   //     event.preventDefault();
    //   //   })
    // }
  },
  methods: {
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    slide (data) {
      if (this._isMobile()) {
        if (data.currentPage == 4) {
          this.showFooter = true;
        }
        else {
          this.showFooter = false;
        }
      }
      //   else {
      // this.options.freeze = false;
      //   }
    },
    onTap (data) {
    },
    onInit (data) {
    },
    copy () {
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        // alert('微信号复制成功，快去添加微信客服吧')
        this.mDialog.show = true;
        this.showVideo = false;
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.mDialog.show = true;
        this.mDialog.title = "该浏览器不支持自动复制";
        this.mDialog.showTxt = false;
        // alert('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy();
        // e.preventDefault();
      })
    },
    hideDialog () {
      this.mDialog.show = false;
      this.showVideo = true;
    },
    stopImgDrag (e) {
      e.target.onmousedown = function (e) {
        e.preventDefault()
      };
    },
    initBtnPos (id) {
      let maxWidth = document.getElementById(id).offsetWidth;
      return {
        width: maxWidth,
        left: maxWidth / 2 + 'px'
      }
    },

    clickImg (e, obj) {
      let left = e.offsetX;
      // 修改拖拽图标位置
      obj.btnLeft = left + 'px';
      //修改老图宽度
      obj.oldPicWidth = left + 'px';
      //修改新图宽度
      obj.newPicWidth = (obj.wrapWidth - left) + 'px';
    },

    //点击翻新模块
    clickReNew (e) {
      this.clickImg(e, this.reNew);
    },
    // 点击精修模块
    clickReFine (e) {
      this.clickImg(e, this.reFine);
    },
    // 点击缩略图部分
    clickSmallPic (type, index) {
      switch (type) {
        case 1:
          this.reNew.index = index;
          break;

        case 2:
          this.reFine.index = index;
          break;

        case 3:
          this.reYoung.index = index;
          this.reYoung.bigvideo.map(x => x.show = false);
          this.reYoung.bigvideo[index].show = true;
          let autoVideo = document.getElementById('videoPlay' + index);
          //   if (autoVideo.requestFullscreen) {
          //     autoVideo.requestFullscreen();
          //   } else if (autoVideo.mozRequestFullScreen) {
          //     autoVideo.mozRequestFullScreen();
          //   } else if (autoVideo.webkitRequestFullScreen) {
          //     autoVideo.webkitRequestFullScreen();
          //   }
          autoVideo.play();
          //   this.reYoung.autoPlay = "autoplay";
          break;
      }
    },
    down (event, id) {
      this.flags = true;
      let moveDiv = document.getElementById(id);
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.dx = moveDiv.offsetLeft;
    },
    mobileMove (e, obj) {
      if (this.flags) {
        let touch;
        if (e.touches) {
          touch = e.touches[0];
        } else {
          touch = e;
        }

        this.nx = touch.clientX - this.position.x;
        // let moveDiv = document.getElementById("moveDiv");
        // this.dx = moveDiv.offsetLeft;
        //   this.nx = touch.clientX - touch.target.offsetLeft;
        let left = this.dx + this.nx;

        //   let left = touch.clientX;
        let maxWidth = obj.wrapWidth;

        if (left < 0 || left > maxWidth) return;

        // 修改拖拽图标位置
        obj.btnLeft = left + 'px';
        //修改老图宽度
        obj.oldPicWidth = left + 'px';
        //修改新图宽度
        obj.newPicWidth = (obj.wrapWidth - left) + 'px';

        //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
        // document.addEventListener("touchmove", function () {
        //   event.preventDefault();
        // }, false);
        //   }

      }
    },
    end () {
      this.flags = false;
    },
    // 移动端翻新拖拽
    moveReNewMobile (e) {
      this.mobileMove(e, this.reNew);
    },
    // 移动端精修拖拽
    moveReFineMobile (e) {
      this.mobileMove(e, this.reFine);
    },


  }
}
</script>

<style lang="less">
@import "./home.less";
@import "./home-phone.less";
</style>
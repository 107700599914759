<template>
  <div class="photo-block-wrap white">
    <div class="photo-block">
      <div class="photo-block-right">
        <div class="photo-title">人工精修破损老照片</div>
        <div class="pc-show">
          <div class="photo-subtitle">资深设计师帮你还原最初记忆</div>
          <div class="photo-intro">10年经验资深修复师一对一精修</div>
          <div class="photo-intro">已成功人工修复6000+张老照片</div>
          <div class="photo-btn"
               @click="jumpCase">查看更多修复样例>></div>
        </div>
        <div class="phone-show">
          <div class="photo-subtitle">10年经验资深修复师一对一精修</div>
          <div class="photo-tip1">初级修复<span>{{reFine.txt[reFine.index]}}</span></div>
          <div class="photo-tip2">将破损的地方修复完整，原片清晰度不变</div>
          <div class="photo-tip3">
            <span class="txt">还原度<b>80%</b>以上，修复时间<b>1-2</b>天</span>
            <span class="btn"
                  @click="jumpCase">样例中心</span>
          </div>
        </div>
      </div>
      <div class="photo-block-left">
        <div class="photo-pic-top"
             id="pic2Wrap">
          <div class="photo-old"
               :style="{width: reFine.oldPicWidth}"
               @click="clickReFine">
            <img :src=reFine.bigPics[reFine.index].old
                 :style="{width: reFine.wrapWidth+'px'}"
                 alt="">
            <span>翻新前</span>
          </div>
          <div class="photo-change pc-show"
               :style="{left: reFine.btnLeft}"
               @mousedown="moveReFine"></div>

          <div class="photo-change phone-show"
               :style="{left: reFine.btnLeft}"
               id="reFineId"
               @touchstart="down"
               @touchmove="moveReFineMobile"
               @touchend="end">
          </div>
          <div class="photo-new"
               :style="{width: reFine.newPicWidth}"
               @click="clickReFine">
            <img :src=reFine.bigPics[reFine.index].new
                 :style="{width: reFine.wrapWidth+'px'}"
                 alt="">
            <span>翻新后</span>
          </div>
        </div>
        <div class="photo-pic-bar">
          <div class="pic-details"
               :class="{'on':reFine.index == index}"
               v-for="(item,index) in reFine.smallPics"
               :key="index"
               @click="clickSmallPic(2,index)">
            <img :src=item.url
                 alt="">
            <span class="low">{{item.title}}</span>
          </div>
        </div>
      </div>
      <div class="photo-contact phone-show">
        <div class="contact-title">立即预约修复</div>
        <div class="contact-main">
          <button type="button"
                  class="contact-type tag-read"
                  data-clipboard-text="13738817358"
                  @click="copy">微信预约<br />点击复制微信号</button>
          <div class="contact-type">
            <a href="tel:051287816685">
              <p class="phone">点击拨打</p>
              <p>{{data.breakTel}}</p>
            </a>
          </div>
        </div>
      </div>
      <div class="home-slide phone-show gray">
      </div>
    </div>
  </div>
</template>

<script>
import data from '../../../static/json/data.json'
import Clipboard from 'clipboard';
export default {
  name: 'home-refine',
  props: {
    reFine: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      data: data,
    }
  },
  methods: {
    copy (e) {
      this.$emit('copy', e);
    },
    clickReFine (e) {
      this.$emit('clickReFine', e);
    },
    moveReFine (e) {
      this.$emit('moveReFine', e);
    },
    moveReFineMobile (e) {
      this.$emit('moveReFineMobile', e);
    },
    clickSmallPic (type, index) {
      this.$emit('clickSmallPic', type, index);
    },
    down (e) {
      this.$emit('down', e, "reFineId");
    },
    end (e) {
      this.$emit('end', e);
    },
    // 跳转更多案例页面
    jumpCase () {
      this.$router.push({
        name: 'cases',
      }).catch(err => { err })
    },

  }
}
</script>
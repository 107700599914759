<template>
  <div>
    <!-- 公用头部 -->
    <photo-header></photo-header>

    <div class="article-list">

      <div class="phone-show">
        <img src="../../../static/imgs/rephoto/phone/article_pic.png"
             alt="">
      </div>

      <h2>黑白照片上色-寻找儿时的记忆</h2>
      <p>更新：2019—11-11 &nbsp;用户：zoeyey周一一 </p>

      <div class="txt">
        我对重庆的好感度，这次因为意外发现一家在半山腰叫做“冻绿房”的民宿而瞬间飙升。司机驱车带我往山上赶 的时候也开始好奇，一直和我确认地址是否正确，因为作为重庆人，他也没来过这里。沿途都是当地人家，有鸡有 狗尽是生活的气息。虽然下车后前往“冻绿房”的小道不是那么美好，因为夹杂着各种家畜的味道，我都是捂着鼻子 的。
      </div>

      <div class="pc-show">
        <img src="../../../static/imgs/rephoto/pc/article_pic1.png"
             alt="">
      </div>

      <div class="txt">
        我对重庆的好感度，这次因为意外发现一家在半山腰叫做“冻绿房”的民宿而瞬间飙升。司机驱车带我往山上赶 的时候也开始好奇，一直和我确认地址是否正确，因为作为重庆人，他也没来过这里。沿途都是当地人家，有鸡有 狗尽是生活的气息。虽然下车后前往“冻绿房”的小道不是那么美好，因为夹杂着各种家畜的味道，我都是捂着鼻子 的。
      </div>

      <div class="article-like">
        赞
      </div>
    </div>

    <!-- 公用底部 -->
    <photo-footer></photo-footer>
  </div>
</template>
<script>
import PhotoHeader from '../../../component/rephoto/header/header.vue';
import PhotoFooter from '../../../component/rephoto/footer/footer.vue';
export default {
  name: "newlist",
  components: {
    PhotoHeader,
    PhotoFooter,
  },
  data () {
    return {
      msg: ""
    }
  }
}
</script>
<style lang="less" scoped>
@import "./news.less";
</style>